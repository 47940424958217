import { CircularProgress, ClickAwayListener } from '@mui/material';
import DateSelector from '../components/DateSelector';
import './Suppression.css';
import dayjs from 'dayjs';
import { React, useState, useEffect } from 'react';
import { CalendarMonthOutlined, CalendarMonthRounded, CheckRounded, CloseRounded, KeyboardArrowDownRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import axios from 'axios';
import ChartComponent from '../components/Charts/Example';
import FileList from '../components/FileList';
import TextInput from '../components/TextInput';


function Suppression ({serverURL, darkMode, refreshFiles, setRefreshFiles, selectedFiles, setSelectedFiles, selectedFileNames, setSelectedFileNames, showSelectionPane, setShowSelectionPane }) {
  const [outputFileName, setOutputFileName] = useState('')
  const [suppressionLoading, setSuppressionLoading] = useState(false)


  console.log( 'MASTER', selectedFiles
    .filter(file => file.attributes.fileType === "master")
    .map(file => file.name));

  console.log( 'EMAIL_SUPP', selectedFiles
    .filter(file => file.attributes.fileType === "supp" && file.attributes.suppType === "email")
    .map(file => file.name));

  console.log('DOMAIN_SUPP', selectedFiles
    .filter(file => file.attributes.fileType === "supp" && file.attributes.suppType === "domain")
    .map(file => file.name));
  
  async function suppressFile() {
    try {
      setSuppressionLoading(true);

      const requestData = {
        csvFilePath: selectedFiles.filter(file => file.attributes.fileType === "master").map(file => file.name)[0],
        suppressionFilePath: selectedFiles.filter(file => file.attributes.fileType === "supp" && file.attributes.suppType === "email").map(file => file.name),
        domainSuppressionFilePath: selectedFiles.filter(file => file.attributes.fileType === "supp" && file.attributes.suppType === "domain").map(file => file.name),
        outputFilePath: outputFileName,
        masterListNumberOfEmails: selectedFiles.filter(file => file.attributes.fileType === "master").map(file => file.attributes.totalEmailCount)[0]
      }

      console.log('Request Data:', requestData);


      const response = await axios.post(`${serverURL}/suppression`, requestData);
      console.log('Response:', response.data);
      setSuppressionLoading(false);
      setRefreshFiles(!refreshFiles);
    } catch (error) {
      console.error('There was an error!', error);
      setSuppressionLoading(false);
    }
  }



  async function updateFBLsAndBounces () {
    try {

      const response1 = await axios.get(`${serverURL}/getAllBounces`);
      const response2 = await axios.get(`${serverURL}/getAllFBLs`);

    } catch (error) {
      console.error('There was an error updating FBLs and Bounces!', error);
    }
  }




    return (
    <div className='SuppressionContainer'>


      <div className='SuppressionOptionsContainer'>
        <input className='SuppressionOutputFileNameInput' type='text' placeholder='Output File Name' onChange={(e) => {setOutputFileName(e.target.value)}} value={outputFileName}  />



          <div className='SuppressionOptionsSubmitRow'>
            {selectedFileNames.length > 0 ?
              <div className='SuppressionOptionsSubmitButton' onClick={() => {suppressFile()}}>
                {suppressionLoading ? 
                  <CircularProgress style={{width: 20, height: 20, color: '#fff'}} />
                  :
                  "Start Suppression"
                }
                <KeyboardArrowRightRounded style={{width: 20, marginLeft: 10}} />
                
              </div>
            :
              <div className='SuppressionOptionsSubmitButtonDisabled'>
                Select files for suppression
              </div>
            }
          </div>

      </div>


      <FileList 
        serverURL={serverURL} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} refreshFiles={refreshFiles} setRefreshFiles={setRefreshFiles}
        selectedFileNames={selectedFileNames} setSelectedFileNames={setSelectedFileNames} showSelectionPane={showSelectionPane} setShowSelectionPane={setShowSelectionPane}
      />
      
          
      
    </div>
  )
}



export default Suppression;