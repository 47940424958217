import '../App.css';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import react, { useState } from 'react';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import {db} from '../FirebaseInit';
import { useNavigate } from 'react-router-dom';
import OmniLoginImage from '../assets/OmniLogin.png';



function SignUp() {
    const navigate = useNavigate()

    const [step, setStep] = useState(0);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');


    function generateRandomId(type) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      if (type == 'uid') {
        result = "u_";
      } else {
        result = "b_";
      }
      const charactersLength = characters.length;
      for (let i = 0; i < 30; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
  }
  
  
    async function signUp () {
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          // Signed in
          setStep(1)
          const user = userCredential.user;
          const userId = "u_" + user.uid;
          console.log("UID: " + userId)
          localStorage.setItem('uid', userId);
          const usersCollection = collection(db, 'users');
          await setDoc(doc(usersCollection, userId), {
            firstName: firstName,
            lastName: lastName,
            initials: firstName.charAt(0) + lastName.charAt(0),
            userID: userId,
            createdAt: new Date(),
            email: email,
            type: 'emailAuth',
          });


          const businessID = generateRandomId('bid');
          localStorage.setItem('bid', businessID);

          const businessCollection = doc(db, 'businesses', businessID);
          setDoc(businessCollection, {
              businessName: '',
              userIDs: [userId],
              createdAt: new Date(),
              businessEmail: email,
              ownerFirstName: '',
              ownerLastName: '',
          })
        
          console.log("BID: ", businessID);

          const userDocRef = doc(db, 'users', userId);
          await setDoc(userDocRef, {
            businessID: businessID,
          }, { merge: true });
         
          



    
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorMessage)
          // ..
        });
      }
  
  
    
  

  
  
  
    return (





      <div className='loginContainer'>
        <div className='loginLeft'>
          <span className='loginLeftLogo'>Sendwell List Manager</span>
          <span className='loginWelcomeText'>Hi, Welcome to Sendwell List Manager</span>
          <img src={OmniLoginImage}/>
        </div>
        <div className='loginRight'>

          {step == 0 && (
            <div className='loginSubContainer'>
              <span className='loginRightText'>Create an account</span>
              <span className='loginRightCreateText'>Already a user? <span className='loginRightCreateLink' onClick={() => {navigate('/Login')}}>Log in</span></span>
              <input className='loginInput' type='email' placeholder='Email' value={email} onChange={(event) => setEmail(event.target.value)}/>
              <input className='loginInput' type='password' placeholder='Password' value={password} onChange={(event) => setPassword(event.target.value)}/>
              <input className='loginInput' type='text' placeholder='First Name' value={firstName} onChange={(event) => setFirstName(event.target.value)}/>
              <input className='loginInput' type='text' placeholder='Last Name' value={lastName} onChange={(event) => setLastName(event.target.value)}/>
              <button className='buttonContainer' onClick={() => {signUp()}}>Sign up</button>
            </div>
          )}


         

        </div>
      </div>
    );
}

export default SignUp;














