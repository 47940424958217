import React from 'react';
import { useNavigate } from 'react-router-dom';
import react, { useState, useEffect, useRef } from 'react';
import { ArrowForwardIosRounded, ArrowForwardRounded, ArrowRightAltRounded, CheckRounded, ChevronRightRounded, CleanHandsRounded, CleaningServicesRounded, CloseRounded, DarkModeRounded, MopedRounded, Notifications, NotificationsRounded, SearchRounded, Settings, SettingsRounded, TuneRounded, Upload, UploadFileRounded, WashRounded } from '@mui/icons-material';
import { getAuth, signOut } from "firebase/auth";
import DrawerTempIcon from '../assets/Icons/DrawerTempIcon';
import './NavbarAndDrawer.css';
import { CircularProgress, ClickAwayListener } from '@mui/material';
import avatar from '../assets/avatar.png';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {db} from '../FirebaseInit';
import TextInput from './TextInput';
import Papa from 'papaparse';
import ChartComponent from './Charts/Example';
import { collection, query, where, onSnapshot, limit, orderBy, doc, updateDoc } from "firebase/firestore";


function NavbarAndDrawer({children, darkMode, setDarkMode, refreshFiles, setRefreshFiles, selectedFiles, setSelectedFiles, selectedFileNames, setSelectedFileNames, showSelectionPane, setShowSelectionPane, userDetails, serverURL}) {
    const navigate = useNavigate()


    const [collapsed, setCollapsed] = useState(false)

    const [showDateSelectors, setShowDateSelectors] = useState(false)
    const [showNotificationsPane, setShowNotificationsPane] = useState(false)
    const [showSearchPopup, setShowSearchPopup] = useState(false)
    const [showProfilePictureDropdown, setShowProfilePictureDropdown] = useState(false)
    const [hideNavAndDrawer, setHideNavAndDrawer] = useState(false);
    const [searchText, setSearchText] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [showUploadPopup, setShowUploadPopup] = useState(false)
    const [uploadFileDetails, setUploadFileDetails] = useState({name: '', size: 0, type: ''})
    const [domainDistributionX, setDomainDistributionX] = useState([])
    const [domainDistributionY, setDomainDistributionY] = useState([])
    const [calculateUniqueEmailsLoading, setCalculateUniqueEmailsLoading] = useState(false)
    const [uploadPopupStep, setUploadPopupStep] = useState(1)
    const [fileUploadType, setFileUploadType] = useState('master')
    const [suppressionFileType, setSuppressionFileType] = useState('')
    const [processes, setProcesses] = useState([])
    const [uploads, setUploads] = useState([])


    const bid = localStorage.getItem('bid')
    const uid = localStorage.getItem('uid')







    async function logOut () {


        const auth = getAuth();
        signOut(auth).then(() => {
          // Sign-out successful.
        }).catch((error) => {
          // An error happened.
        });
    }
    
    
    function displayDate (date) {
        //const date = new Date(); 
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const dayOfWeek = days[date.getDay()];
        const month = months[date.getMonth()];
        const dayOfMonth = date.getDate();
        const year = date.getFullYear();
        const formattedDate = `${dayOfWeek} ${month} ${dayOfMonth}, ${year}`;
        //console.log(formattedDate)
        return formattedDate;
    }


    // Get the current URL path
    const path = window.location.pathname;
    // Split the path by '/'
    const pathSegments = path.split('/');
    // Get the last segment (excluding empty segments)
    const directory = pathSegments.filter(segment => segment !== '').pop();


    function handleKeyPress (event) {
        if (event.keyCode === 27) {
            //esc key pressed
            setShowSearchPopup(false)
        } else if (event.keyCode === 27 || (event.keyCode === 75 && event.metaKey)) {
            setShowSearchPopup(true)
        }
    }


    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        // Remove event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);



    const location = useLocation();

    useEffect(() => {
      // Get the current URL path
      const path = location.pathname;
      // Split the path by '/'
      const pathSegments = path.split('/');
      // Get the last segment (excluding empty segments)
      const directory = pathSegments.filter(segment => segment !== '').pop();
  
      if (directory === 'Login' || directory === 'LogIn' || directory === 'Signup' || directory === 'SignUp' || directory === undefined) {
        setHideNavAndDrawer(true);
      } else {
        setHideNavAndDrawer(false);
      }

    }, [location.pathname]);









    const fileInputRef = useRef(null);
    const [files, setFiles] = useState([]);
    const [columnNames, setColumnNames] = useState([]);
    const [selectedColumn, setSelectedColumn] = useState('');
    const [fileToUpload, setFileToUpload] = useState(null);
    const [uploadFileError, setUploadFileError] = useState(null);
    const [uploading, setUploading] = useState(false);






    // Function to trigger file input click
    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // Function to handle file selection and upload
    const handleFileChange = async (event) => {
        const selectedFiles = event.target.files;
        setFileToUpload(selectedFiles[0]);
        getColumnNames(selectedFiles)
        if (selectedFiles.length > 0) {

            // Initialize an array to store file upload progress
            
            
        }
    };



    const getColumnNames = (files) => {
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            const CHUNK_SIZE = 1024 * 1024; // 1 MB chunk size
    
            reader.onload = (event) => {
                const text = event.target.result;
                const lines = text.split('\n');
                if (lines.length > 0) {
                    const columns = lines[0].split(',').map(col => col.trim());
                    console.log('Columns:', columns);
    
                    setColumnNames(columns);
                    setShowUploadPopup(true);
                    setUploadFileDetails(file);
                }
            };
    
            reader.onerror = (event) => {
                console.error('Error reading file:', event.target.error);
                alert('Error reading CSV file.');
            };
    
            const readFirstLine = () => {
                const blob = file.slice(0, CHUNK_SIZE); // Read only the first chunk
                reader.readAsText(blob);
            };
    
            readFirstLine(); // Start reading the first chunk
        } else {
            alert('Please upload a CSV file.');
        }
    };
    
    
    
  
    




    // Function to upload a single file and track progress

    const uploadFile = async (file, selectedColumn) => {
        console.log(file.name)
        try {
            setUploading(true);
            const formdata = new FormData();
            formdata.append("emailColumn", selectedColumn);
            formdata.append("fileType", fileUploadType);
            formdata.append("file", file);
            formdata.append("suppType", suppressionFileType);
            
            const requestOptions = {
                method: "POST",
                body: formdata,
                redirect: "follow"
            };
            
            fetch(`${serverURL}/uploadFile`, requestOptions)
            .then((response) => {
                response.text()
            })
            .then((result) => {
                console.log(result)
                setRefreshFiles(!refreshFiles);
                setShowUploadPopup(false);
                setUploading(false);
                setUploadPopupStep(1);
            })
            .catch((error) => console.error(error));
        

        } catch (error) {
            console.log( "ERROR:", error )
            setUploading(false);
            setUploadPopupStep(1);
            setUploadFileError(error.message || 'Upload failed');
        }


    };
    
    





    useEffect(() => {
        if (selectedFiles.length > 0) {
            const fromAPI = selectedFiles[0]?.attributes?.emailsByDomain;

            let validJSONString = fromAPI
            ?.replace(/(\w+):/g, '"$1":') // Add quotes around keys
            ?.replace(/:([^",}]+)([,}])/g, ':"$1"$2'); // Add quotes around values
        


            if (!validJSONString) return;

            console.log(validJSONString)
            const emailCounts = JSON.parse(validJSONString) || {domain: '', count: 0};
            console.log(emailCounts)

            let tempDomains = [];
            let tempCounts = [];
            for (let i = 0; i < emailCounts.length; i++) {
                tempDomains.push(emailCounts[i].domain);
                tempCounts.push(emailCounts[i].count);
            }

            setDomainDistributionX(tempCounts.slice(0,5) || []);
            setDomainDistributionY(tempDomains.slice(0,5) || []);
        }

    }, [selectedFiles])


    function calculateUniqueEmails () {
        setCalculateUniqueEmailsLoading(true)
    }


    function formatFileSize(bytes) {
        if (bytes === 0) return '0 Bytes';
    
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        const formattedSize = (bytes / Math.pow(1024, i)).toFixed(2);
        return formattedSize + ' ' + sizes[i];
    }
    






    async function getProcesses() {
        const q = query(collection(db, "processes"), limit(10), orderBy('startTime', 'desc'), where('status', '==', 'running'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const processesTemp = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
      
            // Extract startTime and endTime
            const startDate = data.startTime.seconds * 1000;
            const endDate = data.endTime ? data.endTime.seconds * 1000 : new Date();
          
            // Calculate duration in milliseconds
            const durationMs = endDate - startDate;
      
            // Convert milliseconds to hours, minutes, and seconds
            const hours = Math.floor(durationMs / (1000 * 60 * 60));
            const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((durationMs % (1000 * 60)) / 1000);
      
            // Format the duration
            let formattedDuration;
            if (hours > 0) {
              formattedDuration = `${hours}h ${minutes}m ${seconds}s`;
            } else if (minutes > 0) {
              formattedDuration = `${minutes}m ${seconds}s`;
            } else {
              formattedDuration = `${seconds}s`;
            }
      
            // Add formatted duration to data
            processesTemp.push({
              ...data,
              formattedDuration
            });
          });
          setProcesses(processesTemp);
        });
      
        return unsubscribe;
    }
    
    
    
    
    
    async function getUploads() {
        const q = query(collection(db, "uploads"), limit(10), orderBy('startTime', 'desc'), where('status', '==', 'running'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const uploadsTemp = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
      
            // Extract startTime and endTime
            const startDate = data.startTime.seconds * 1000;
            const endDate = data.endTime ? data.endTime.seconds * 1000 : new Date();
      
            console.log(startDate, endDate); 
    
            // Calculate duration in milliseconds
            const durationMs = endDate - startDate;
            console.log(durationMs);
      
            // Convert milliseconds to hours, minutes, and seconds
            const hours = Math.floor(durationMs / (1000 * 60 * 60));
            const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((durationMs % (1000 * 60)) / 1000);
      
            // Format the duration
            let formattedDuration;
            if (hours > 0) {
              formattedDuration = `${hours}h ${minutes}m ${seconds}s`;
            } else if (minutes > 0) {
              formattedDuration = `${minutes}m ${seconds}s`;
            } else {
              formattedDuration = `${seconds}s`;
            }
      
            // Add formatted duration to data
            uploadsTemp.push({
              ...data,
              formattedDuration
            });
          });
          setUploads(uploadsTemp);
        });
      
        return unsubscribe;
    }
    
    
    useEffect(() => {
        getProcesses();
        getUploads();
    }, [])







    useEffect(() => {
        if (searchText.length > 0) {
            searchFiles();
        }
    }, [searchText])


    async function searchFiles () {
        try {
            const response = await axios.get(`${serverURL}/searchFiles?query=${searchText}`);
            setSearchResults(response.data);
        } catch (error) {
            console.log('Error searching files:', error)
        }
    }





    const downloadFile = async (filename) => {
        try {
          const response = await axios.get(`${serverURL}/downloadFile?fileName=${filename}`, {
            responseType: 'blob', // Set responseType to 'blob' for binary data
          });
      
          // Create a URL for the blob
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename); // Specify the file name for the download
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url); // Clean up the URL object
        } catch (error) {
          console.error('Error downloading the file:', error);
        }
    };


    async function markProcessAsComplete (process) {
        try {
            const processRef = doc(db, "processes", process.processID);
            await updateDoc(processRef, {
                endTime: new Date(),
                status: 'done'
            });

        } catch (error ) {
            console.log('Error marking process as complete:', error)
        }
    }


    async function markUploadAsComplete (process) {
        try {
            const uploadRef = doc(db, "uploads", process.processID);
            await updateDoc(uploadRef, {
                endTime: new Date(),
                status: 'done'
            });

        } catch (error ) {
            console.log('Error marking process as complete:', error)
        }
    }




    return ( 
        
        <div className={'navContainer ' + (darkMode && ' darkMode')} style={{
            //filter: continueSignUp && 'blur(10px)',
        }}>
            <div className={collapsed ? 'navLeftContainerCollapsed' : 'navLeftContainer'} style={{display: hideNavAndDrawer && 'none', borderRight: darkMode && '1px dashed #222', backgroundColor: darkMode ? '#141a21f5' : '#e7e7e760'}}>
                <div className='navExpandCollapseIconContainer' style={{transform: collapsed ? 'none' : 'rotate(180deg)', backgroundColor: darkMode && '#141a21f5', border: darkMode && '1px solid #444'}} onClick={() => {setCollapsed(!collapsed)}}><ArrowForwardIosRounded fontSize='10'/> </div>
                <div className='navLeftContainerTop'>
                <span className='navLeftContainerTitle' style={{color: darkMode && '#fff', fontSize: collapsed ? 15 : 25}}>Sendwell Lists</span>
                <span className='navLeftContainerSubtitle' style={{display: collapsed ? 'none' : null}}>List Management</span>
        
                <div className={directory == 'Dashboard' ? 'navLeftContainerItemSelected' : 'navLeftContainerItem'} onClick={() => navigate('/Dashboard')}>
                    <div className={collapsed ? 'navLeftContainerItemLeftCollapsed' : 'navLeftContainerItemLeft'}>
                        <DrawerTempIcon active={directory == 'Dashboard' ? true : false}/>
                        <span className={collapsed ? 'navLeftContainerItemLeftTextCollapsed' : 'navLeftContainerItemLeftText'}>Dashboard</span>
                    </div>
                </div>
        
        
                <div className={directory == 'Count' ? 'navLeftContainerItemSelected' : 'navLeftContainerItem'} onClick={() => navigate('/Count')}>
                    <div className={collapsed ? 'navLeftContainerItemLeftCollapsed' : 'navLeftContainerItemLeft'}>
                        <DrawerTempIcon active={directory == 'Count' ? true : false}/>
                        <span className={collapsed ? 'navLeftContainerItemLeftTextCollapsed' : 'navLeftContainerItemLeftText'}>Count</span>
                    </div>
                </div>


                <div className={directory == 'Columns' ? 'navLeftContainerItemSelected' : 'navLeftContainerItem'} onClick={() => navigate('/Columns')}>
                    <div className={collapsed ? 'navLeftContainerItemLeftCollapsed' : 'navLeftContainerItemLeft'}>
                        <DrawerTempIcon active={directory == 'Columns' ? true : false}/>
                        <span className={collapsed ? 'navLeftContainerItemLeftTextCollapsed' : 'navLeftContainerItemLeftText'}>Columns</span>
                    </div>
                </div>
                
        
                <div className={directory == 'Cleaning' ? 'navLeftContainerItemSelected' : 'navLeftContainerItem'} onClick={() => navigate('/Cleaning')}>
                    <div className={collapsed ? 'navLeftContainerItemLeftCollapsed' : 'navLeftContainerItemLeft'}>
                        <DrawerTempIcon active={directory == 'Cleaning' ? true : false}/>
                        <span className={collapsed ? 'navLeftContainerItemLeftTextCollapsed' : 'navLeftContainerItemLeftText'}>Cleaning</span>
                    </div>
                </div>
        
                <div className={directory == 'Combine' ? 'navLeftContainerItemSelected' : 'navLeftContainerItem'} onClick={() => navigate('/Combine')}>
                    <div className={collapsed ? 'navLeftContainerItemLeftCollapsed' : 'navLeftContainerItemLeft'}>
                        <DrawerTempIcon active={directory == 'Combine' ? true : false}/>
                        <span className={collapsed ? 'navLeftContainerItemLeftTextCollapsed' : 'navLeftContainerItemLeftText'}>Combine</span>
                    </div>
                </div>
        
                <div className={directory == 'Suppression' ? 'navLeftContainerItemSelected' : 'navLeftContainerItem'} onClick={() => navigate('/Suppression')}>
                    <div className={collapsed ? 'navLeftContainerItemLeftCollapsed' : 'navLeftContainerItemLeft'}>
                        <DrawerTempIcon active={directory == 'Suppression' ? true : false}/>
                        <span className={collapsed ? 'navLeftContainerItemLeftTextCollapsed' : 'navLeftContainerItemLeftText'}>Suppression</span>
                    </div>
                </div>
                
        
                <div className={directory == 'Sample' ? 'navLeftContainerItemSelected' : 'navLeftContainerItem'} onClick={() => navigate('/Sample')}>
                    <div className={collapsed ? 'navLeftContainerItemLeftCollapsed' : 'navLeftContainerItemLeft'}>
                        <DrawerTempIcon active={directory == 'Sample' ? true : false}/>
                        <span className={collapsed ? 'navLeftContainerItemLeftTextCollapsed' : 'navLeftContainerItemLeftText'}>Sample</span>
                    </div>
                </div>


                <span className='navLeftContainerSubtitle' style={{display: collapsed ? 'none' : null}}>Sendy</span>

                <div className={directory == 'Accounts' ? 'navLeftContainerItemSelected' : 'navLeftContainerItem'} onClick={() => navigate('/Accounts')}>
                    <div className={collapsed ? 'navLeftContainerItemLeftCollapsed' : 'navLeftContainerItemLeft'}>
                        <DrawerTempIcon active={directory == 'Accounts' ? true : false}/>
                        <span className={collapsed ? 'navLeftContainerItemLeftTextCollapsed' : 'navLeftContainerItemLeftText'}>Accounts</span>
                    </div>
                </div>
               
        
        
        
        
        
        
        
        
        
        
        
        
        
        
                </div>
                <div className='navLeftContainerBottom'>

                    {/* <div className={directory == 'Settings' ? 'navLeftContainerItemSelected' : 'navLeftContainerItem'} onClick={() => navigate('/Settings')}>
                        <div className={collapsed ? 'navLeftContainerItemLeftCollapsed' : 'navLeftContainerItemLeft'}>
                            <DrawerTempIcon active={directory == 'Settings' ? true : false}/>
                            <span className={collapsed ? 'navLeftContainerItemLeftTextCollapsed' : 'navLeftContainerItemLeftText'}>Settings</span>
                        </div>
                    </div> */}

                    <div className='navLeftContainerItem'  onClick={() => {logOut()}}>
                        <div className={collapsed ? 'navLeftContainerItemLeftCollapsed' : 'navLeftContainerItemLeft'}>
                            <DrawerTempIcon active={false}/>
                            <span className={collapsed ? 'navLeftContainerItemLeftTextCollapsed' : 'navLeftContainerItemLeftText'}>Logout</span>
                        </div>
                    </div>
                
                </div>
            </div>
        
            <div className={(collapsed ? 'navNavbarContainerCollapsed ' : 'navNavbarContainer ') + (darkMode && ' darkMode')} style={{display: hideNavAndDrawer && 'none'}}>
                <div className='navNavbarLeftContainer'>
                    <div className='navNavbarLeftSearchContainer' onClick={() => {setShowSearchPopup(true)}}>
                        <div>
                            <SearchRounded style={{color: '#637381'}}/>
                        </div>
                        <span className='navNavbarLeftSearchKeysText'>⌘K</span>
                    </div>
                </div>
                <div className='navNavbarRightContainer'>
                    
                    {(processes.length > 0 || uploads.length > 0) && (
                        <div className='navNavbarRightContainerIcon' onClick={() => {setShowNotificationsPane(true)}}>
                            <CircularProgress style={{width: 20, height: 20, color: '#00a76f'}}/>
                        </div>
                    )}
                    {/* 
                    <div className='navNavbarRightContainerIcon' onClick={() => {setDarkMode(!darkMode)}}>
                        <DarkModeRounded/>
                    </div>
                     */}
                    {/* <div className='navNavbarRightContainerSettingsIcon' onClick={() => {navigate('/Settings')}}>
                        <SettingsRounded/>
                    </div> */}
                    <div className='navNavbarRightContainerAvatarContainer' onClick={() => {setShowProfilePictureDropdown(!showProfilePictureDropdown)}}>
                        <span className='navNavbarRightContainerAvatarText'>{userDetails?.initials}</span>                    
                    </div>
                    {showProfilePictureDropdown && (
                        <div className='navNavbarProfileDropdown'>
                            <div className='navNavbarProfileDropdownSection1'>
                                <span className='navNavbarProfileDropdownSection1Name'>{userDetails?.firstName} {userDetails?.lastName}</span>
                                <span className='navNavbarProfileDropdownSection1Email'>{userDetails?.email}</span>
                            </div>
                            {/* <div className='navNavbarProfileDropdownSection2'>
                                <span className='navNavbarProfileDropdownSection2Link'>Home</span>
                                <span className='navNavbarProfileDropdownSection2Link'>Profile</span>
                                <span className='navNavbarProfileDropdownSection2Link'>Settings</span>
                            </div> */}
                            <div className='navNavbarProfileDropdownSection3' onClick={() => { setShowProfilePictureDropdown(false); logOut();}}>
                                <span className='navNavbarProfileDropdownSection3LogoutButton'>Logout</span>
                            </div>
                        </div>
                    )}



        
                    {showDateSelectors && (
                    <>
                    <div className='navNavbarDateSelector'>
                        <span className='navNavbarDateSelectorText'>{displayDate(new Date())}</span>
                    </div>
                    <div className='navNavbarDateSelector'>
                        <span className='navNavbarDateSelectorText'>{displayDate(new Date())}</span>
                    </div>
                    </>
                    )}
                </div>
                
            </div>
        
        
            <div 
                className={(collapsed ? 'navRightContainerCollapsed ' : 'navRightContainer ') + (darkMode && ' darkMode')} 
                style={{
                    padding: hideNavAndDrawer && 0,
                    minHeight: hideNavAndDrawer && '100vh',
                }}
            >
                {children}



                <div>
                    {/* Hidden file input */}
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    
                    {/* Upload button */}
                    <div className='navUploadFAB' onClick={handleUploadClick}>
                        <Upload style={{ color: '#00a76f' }} />
                    </div>
                </div>


                    {/* SEARCH POPUP */}
                    <div className={showSearchPopup ? 'navSearchPopupBackground' : 'navSearchPopupBackgroundHidden'}>
                        <div className='navSearchPopup'>
                            <div className='navSearchTopContainer'>
                                <div className='navSearchTopSearchIcon'>
                                    <SearchRounded/>
                                </div>
                                <input className='navSearchTopInput' placeholder='Search...' value={searchText} onChange={(e) => {setSearchText(e.target.value)}}/>
                                <span className='navSearchTopESCButton' onClick={() => {setShowSearchPopup(false)}}>Esc</span>
                            </div>
                            <div className='navSearchResultsContainer'>
                                {searchResults.length > 0 && <div className='navSearchResultsCategory'>Search Results</div>}
                                {searchResults.map((x, index) => (
                                    <div className='navSearchResult' key={index} onClick={() => {setSelectedFiles([x]); setSelectedFileNames([x.name]); setShowSearchPopup(false); setSearchText(''); setSearchResults([])}}>
                                        <span className='navSearchResultTitle'>{x.name}</span>
                                        <span className='navSearchResultDescription'>{x.status}</span>
                                        <div className='navSearchResultLinkRow'>
                                            <span className='navSearchResultLinkRowLink' onClick={() => {navigate(`/Cleaning`); setShowSearchPopup(false)}}>Cleaning</span>
                                            <span className='navSearchResultLinkRowLink' onClick={() => {navigate(`/Combine`); setShowSearchPopup(false)}}>Combine</span>
                                            <span className='navSearchResultLinkRowLink' onClick={() => {navigate(`/Suppress`); setShowSearchPopup(false)}}>Suppress</span>
                                            <span className='navSearchResultLinkRowLink' onClick={() => {navigate(`/Sample`); setShowSearchPopup(false)}}>Sample</span>
                                            <span className='navSearchResultLinkRowLink' onClick={() => {downloadFile(x.name)}}>Download</span>
                                        </div>
                                    </div>
                                ))}
                                
                            </div>

                        </div>
                    </div>








                     {/* UPLOAD POPUP */}
                     <div className={showUploadPopup ? 'navUploadPopupBackground' : 'navUploadPopupBackgroundHidden'}>
                        <div className='navUploadPopup'>
                            <div className='navUploadPopupCloseIcon'>
                                <CloseRounded style={{color: '#666', cursor: 'pointer'}} onClick={() => {setShowUploadPopup(false)}}/>
                            </div>
                            
                            {uploadPopupStep == 1 && (
                                <div className='navUploadPopupStep'>
                                    <span className='navUploadHeaderText'>Select the file type</span>
                                    <span>{uploadFileError}</span>

                                    <div className='navUploadColumnNamesContainer'>    
                                        <span className={fileUploadType == 'master' ? 'navUploadColumnNameSelected' : 'navUploadColumnName'} onClick={() => setFileUploadType('master')}>Master List</span>
                                        <span className={fileUploadType == 'supp' ? 'navUploadColumnNameSelected' : 'navUploadColumnName'} onClick={() => setFileUploadType('supp')}>Suppression</span>
                                    </div>

                                    {fileUploadType == 'supp' && (
                                        <div className='navUploadColumnNamesContainer'>    
                                            <span className={suppressionFileType == 'email' ? 'navUploadColumnNameSelected' : 'navUploadColumnName'} onClick={() => setSuppressionFileType('email')}>Email Suppression</span>
                                            <span className={suppressionFileType == 'domain' ? 'navUploadColumnNameSelected' : 'navUploadColumnName'} onClick={() => setSuppressionFileType('domain')}>Domain Suppression</span>
                                        </div>
                                    )}

                                    <div className='navUploadPopupButtonContainer'>
                                        <span 
                                            className='navUploadPopupButton' 
                                            onClick={() => {
                                                if (fileUploadType === 'supp') {
                                                    setSelectedColumn();
                                                    uploadFile(fileToUpload, columnNames[0]);
                                                } else {
                                                    setUploadPopupStep(2);
                                                }
                                            }}
                                        >
                                            {uploading ? "Uploading and Processing" : "Continue"}
                                        </span>
                                    </div>
                                </div>
                            )}

                            

                            {uploadPopupStep == 2 && (
                                <div className='navUploadPopupStep'>
                                    <span className='navUploadHeaderText'>Select the email address column</span>
                                    <span>{uploadFileError}</span>
                                    <div className='navUploadColumnNamesContainerScrollContainer'> 
                                        <div className='navUploadColumnNamesContainer'>
                                            {columnNames.map((x) => { return (
                                                <span key={x} className={selectedColumn == x ? 'navUploadColumnNameSelected' : 'navUploadColumnName'} onClick={() => setSelectedColumn(x)}>{x}</span>
                                            )})}
                                        </div>
                                    </div>
                                    <div className='navUploadPopupButtonContainer'>
                                        <span className='navUploadPopupButton' onClick={() => {uploadFile(fileToUpload, selectedColumn)}}>{uploading ? "Uploading and Processing" : "Upload"}</span>
                                    </div>
                                </div>
                            )}
                            
                        </div>
                    </div>







                    


                    {/* IN PROGRESS PANE */}
                    <div className={`navNotificationRightPane ${showNotificationsPane ? 'open': ''}`}>
                        <div className='navNotificationRightPaneHeaderContainer'>
                            <span className='navNotificationRightPaneHeaderCollapseIcon' onClick={() => {setShowNotificationsPane(false)}}>
                                <ChevronRightRounded style={{color: '#666'}}/>
                            </span>
                            <span className='navNotificationRightPaneHeaderTitle'>In Progress</span>
                            <span className='navNotificationRightPaneHeaderCollapseIcon' style={{opacity: 0}}>
                                <ChevronRightRounded style={{color: '#666'}}/>
                            </span>
                        </div>
                        <div className='navNotificationRightPaneContent'>

                            {uploads.length > 0 && (
                                <div className='navNotificationRightPaneContentSection'>
                                    <span className='navNotificationRightPaneContentSectionTitle'>Uploads</span>
                                    {uploads.map((x, index) => (
                                        <div key={index} className='navNotificationRightPaneContentSectionItem'>
                                            <div className='navNotificationRightPaneContentSectionItemProgress' onClick={() => {markUploadAsComplete(x)}}>
                                                <CircularProgress style={{width: 20, height: 20, color: '#00a76f'}}/>
                                            </div>
                                            <span className='navNotificationRightPaneContentSectionItemTitle'>{x.name}</span>
                                            <span className='navNotificationRightPaneContentSectionItemDescription'>{x.description}</span>
                                            {/* <span className='navNotificationRightPaneContentSectionItemDescription'>{x.formattedDuration}</span> */}
                                        </div>
                                    ))}
                                </div>
                            )}

                            {processes.length > 0 && (
                                <div className='navNotificationRightPaneContentSection'>
                                    <span className='navNotificationRightPaneContentSectionTitle'>Processes</span>
                                    {processes.map((x, index) => (
                                        <div key={index} className='navNotificationRightPaneContentSectionItem'>
                                            <div className='navNotificationRightPaneContentSectionItemProgress' onClick={() => {markProcessAsComplete(x)}}>
                                                <CircularProgress style={{width: 20, height: 20, color: '#00a76f'}}/>
                                            </div>
                                            <span className='navNotificationRightPaneContentSectionItemTitle'>{x.name}</span>
                                            <span className='navNotificationRightPaneContentSectionItemDescription'>{x.description}</span>
                                            {/* <span className='navNotificationRightPaneContentSectionItemDescription'>{x.formattedDuration}</span> */}
                                        </div>
                                    ))}
                                </div>
                            )}
                            

                        </div>
                    </div>




                    {/* SELECTION ACTIONS PANE */}
                    <div className={`navSelectionRightPane ${showSelectionPane ? 'open': ''}`}>
                        <div className='navSelectionRightPaneHeaderContainer'>
                            <span className='navSelectionRightPaneHeaderCollapseIcon' onClick={() => {setShowSelectionPane(false)}}>
                                <ChevronRightRounded style={{color: '#666'}}/>
                            </span>
                            <span className='navSelectionRightPaneHeaderTitle'>{selectedFiles.length} Selected</span>
                            <span className='navSelectionRightPaneHeaderCollapseIcon' style={{opacity: 0}}>
                                <ChevronRightRounded style={{color: '#666'}}/>
                            </span>
                        </div>
                        <div className='navSelectionRightPaneContent'>

                            <span className='navSelectionRightPaneContentFileName'>{selectedFileNames[0]}</span>
                            
                            <ChartComponent 
                                chartName="Domain Distribution" line1Data={domainDistributionX} line2Data={[]} line3Data={[]} categories={domainDistributionY} yAxisIsNumber={false} xAxisIsDate={false} xAxisIsTime={false} 
                                chartHeight={200} total='' compareTotal={0} line1Name="Emails" line2Name={""} line3Name={""} titleType={'number'} chartType="bar"
                                maxTicksX={5} maxTicksY={5} xAxisLabelSize={10} yAxisLabelSize={10} chartContainerStyles={{}} darkMode={false} /> 

                            <div className='navSelectionRightPaneContentDetailsRow'>
                                <span className='navSelectionRightPaneContentDetailsText'>Selected</span>
                                <span className='navSelectionRightPaneContentDetailsAmount'>{selectedFiles.length}</span>
                            </div>

                            <div className='navSelectionRightPaneContentDetailsRow'>
                                <span className='navSelectionRightPaneContentDetailsText'>Size</span>
                                <span className='navSelectionRightPaneContentDetailsAmount'>{formatFileSize(selectedFiles.reduce((sum, item) => sum + (item.size || 0), 0))}</span>
                            </div>

                            <div className='navSelectionRightPaneContentDetailsRow'>
                                <span className='navSelectionRightPaneContentDetailsText'>Emails</span>
                                <span className='navSelectionRightPaneContentDetailsAmount'>{new Intl.NumberFormat('en-US').format(selectedFiles.reduce((sum, item) => sum + (item?.attributes?.totalEmailCount * 1 || 0), 0))}</span>
                            </div>

                            <div className='navSelectionRightPaneContentDetailsRow'>
                                <span className='navSelectionRightPaneContentDetailsText'>Unique</span>
                                <span className='navSelectionRightPaneContentDetailsAmount'>{new Intl.NumberFormat('en-US').format(selectedFiles.reduce((sum, item) => sum + (item?.attributes?.uniqueEmails * 1 || 0), 0))}</span>
                            </div>

                        </div>
                    </div>




 

            </div>
        </div>
    );
}

export default NavbarAndDrawer;