import './Dashboard.css';
import { getAuth, signOut } from "firebase/auth";
import react, { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot, limit, orderBy } from "firebase/firestore";
import {db} from '../FirebaseInit';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavbarAndDrawer from '../components/NavbarAndDrawer';
import Example from '../components/Charts/Example';
import ChartComponent from '../components/Charts/Example';
import { CircularProgress, ClickAwayListener } from '@mui/material';
import { CalendarMonthOutlined, KeyboardArrowDownRounded } from '@mui/icons-material';
import DateSelector from '../components/DateSelector';
import dayjs from 'dayjs';
import FileList from '../components/FileList';
import { formatDuration, intervalToDuration } from 'date-fns';


function Dashboard({serverURL, darkMode}) {
  const navigate = useNavigate()


  const [cpuUsage, setCpuUsage] = useState('0%');
  const [cpuUsageNumber, setCpuUsageNumber] = useState([]);
  const [cpuCores, setCpuCores] = useState(0);
  const [cpuModel, setCpuModel] = useState(0);
  const [ramTotal, setRamTotal] = useState(0);
  const [ramUsed, setRamUsed] = useState(0);
  const [networkDownload, setNetworkDownload] = useState(0);
  const [networkUpload, setNetworkUpload] = useState(0);
  const [storageTotal, setStorageTotal] = useState(0);  
  const [storageUsed, setStorageUsed] = useState(0);

  const [processes, setProcesses] = useState([]);
  const [uploads, setUploads] = useState([])



  const uid = localStorage.getItem('uid');
  const bid = localStorage.getItem('bid');



  async function getProcesses() {
    const q = query(collection(db, "processes"), limit(10), orderBy('startTime', 'desc'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const processesTemp = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
  
        // Extract startTime and endTime
        const startDate = data.startTime.seconds * 1000;
        const endDate = data.endTime ? data.endTime.seconds * 1000 : new Date();
  
        //console.log(startDate, endDate); 

        // Calculate duration in milliseconds
        const durationMs = endDate - startDate;
        //console.log(durationMs);
  
        // Convert milliseconds to hours, minutes, and seconds
        const hours = Math.floor(durationMs / (1000 * 60 * 60));
        const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((durationMs % (1000 * 60)) / 1000);
  
        // Format the duration
        let formattedDuration;
        if (hours > 0) {
          formattedDuration = `${hours}h ${minutes}m ${seconds}s`;
        } else if (minutes > 0) {
          formattedDuration = `${minutes}m ${seconds}s`;
        } else {
          formattedDuration = `${seconds}s`;
        }
  
        // Add formatted duration to data
        processesTemp.push({
          ...data,
          formattedDuration
        });
      });
      setProcesses(processesTemp);
    });
  
    return unsubscribe;
  }





  async function getUploads() {
    const q = query(collection(db, "uploads"), limit(10), orderBy('startTime', 'desc'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const uploadsTemp = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
  
        // Extract startTime and endTime
        const startDate = data.startTime.seconds * 1000;
        const endDate = data.endTime ? data.endTime.seconds * 1000 : new Date();
  
        //console.log(startDate, endDate); 

        // Calculate duration in milliseconds
        const durationMs = endDate - startDate;
        //console.log(durationMs);
  
        // Convert milliseconds to hours, minutes, and seconds
        const hours = Math.floor(durationMs / (1000 * 60 * 60));
        const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((durationMs % (1000 * 60)) / 1000);
  
        // Format the duration
        let formattedDuration;
        if (hours > 0) {
          formattedDuration = `${hours}h ${minutes}m ${seconds}s`;
        } else if (minutes > 0) {
          formattedDuration = `${minutes}m ${seconds}s`;
        } else {
          formattedDuration = `${seconds}s`;
        }
  
        // Add formatted duration to data
        uploadsTemp.push({
          ...data,
          formattedDuration
        });
      });
      setUploads(uploadsTemp);
    });
  
    return unsubscribe;
  }









  useEffect(() => {
    getProcesses();
    getUploads();
  }, [])




  useEffect(() => {
    // Create a new EventSource to connect to the streaming endpoint
    const source = new EventSource(`${serverURL}/systemUsage`);
  
    // Listen for messages from the server
    source.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const cpuUsageTemp = data.cpuUsage;
      const cpuCoresTemp = data.cpuUsage.length;

  
      // Calculate the total CPU usage
      const totalCpuUsage = cpuUsageTemp.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.usage);
      }, 0);
  

      setCpuCores(cpuCores);
      setCpuUsage((totalCpuUsage / cpuCoresTemp).toFixed(2) + '%');
      setCpuUsageNumber(totalCpuUsage / cpuCoresTemp);
      setCpuModel(cpuUsageTemp[0].model);
      setRamTotal(Number(data.ramUsage.totalMem));
      setRamUsed(Number(data.ramUsage.usedMem));
      setNetworkDownload(Number(data.networkUsage.downloadSpeed));
      setNetworkUpload(Number(data.networkUsage.uploadSpeed));
      setStorageTotal(Number(data.diskUsage.total));
      setStorageUsed(Number(data.diskUsage.used));
    };
  
    // Clean up when component unmounts
    return () => {
      source.close();
    };
  }, []);
  



  


  
  return (
    <div className="dashboardContainer">


      <div className="dashboardTopGridView">

        <div className='dashboardTopGridViewCard'>
          <div className='dashboardTopGridViewCardTitle'>CPU</div>
          <div className='dashboardTopGridViewCardSubtitle'>{cpuModel}</div>
          <div className='dashboardTopGridViewCardValue'>{cpuUsage}</div>
          <div className='dashboardTopGridViewCardProgressBarParent'>
            <div className='dashboardTopGridViewCardProgressBarChild' style={{width: cpuUsageNumber + '%'}}></div>
          </div>
        </div>

        <div className='dashboardTopGridViewCard'>
          <div className='dashboardTopGridViewCardTitle'>RAM</div>
          <div className='dashboardTopGridViewCardSubtitle'>{(ramTotal / 1000).toFixed(2)} GB</div>
          <div className='dashboardTopGridViewCardValue'>{(ramUsed / 1000).toFixed(2)} GB</div>
          <div className='dashboardTopGridViewCardProgressBarParent'>
            <div className='dashboardTopGridViewCardProgressBarChild' style={{width: (ramUsed / ramTotal) * 100 + '%'}}></div>
          </div>
        </div>

        <div className='dashboardTopGridViewCard'>
          <div className='dashboardTopGridViewCardTitle'>Disk</div>
          <div className='dashboardTopGridViewCardSubtitle'>{storageTotal} GB</div>
          <div className='dashboardTopGridViewCardValue'>{storageUsed} GB</div>
          <div className='dashboardTopGridViewCardProgressBarParent'>
            <div className='dashboardTopGridViewCardProgressBarChild' style={{width: (storageUsed / storageTotal) * 100 + '%'}}></div>
          </div>
        </div>

        <div className='dashboardTopGridViewCard'>
          <div className='dashboardTopGridViewCardTitle'>Network</div>
          <div className='dashboardTopGridViewCardSubtitle'>{networkUpload} MB/s</div>
          <div className='dashboardTopGridViewCardValue'>{networkDownload} MB/s</div>
          <div className='dashboardTopGridViewCardProgressBarParent' style={{backgroundColor: '#00a76f50'}}>
            <div className='dashboardTopGridViewCardProgressBarChild' style={{width: (networkDownload / (networkDownload + networkUpload)) * 100 + '%'}}></div>
          </div>
        </div>

      </div>

      



      <div className="dashboardMiddleGridView">

        <div className='dashboardMiddleGridViewSection'>
          <span className='dashboardMiddleGridViewSectionTitle'>Tasks</span>

          <div className='dashboardMiddleGridViewSectionCard'>

          </div>
        </div>

        <div className='dashboardMiddleGridViewSection'>
          <span className='dashboardMiddleGridViewSectionTitle'>Uploads</span>

          {uploads.map((x, index) => { return (
            <div key={index} className='dashboardMiddleGridViewSectionCard'>
              <span className='dashboardMiddleGridViewSectionCardName'>{x.name}</span>
              <span className='dashboardMiddleGridViewSectionCardDescription'>{x.description.length > 50 ? x.description.slice(0,50) + '...' : x.description}</span>
              {x.status === 'done' &&
                <span className='dashboardMiddleGridViewSectionCardStatus'> 
                  <span style={{color: '#00a76f'}}>Done ({x.formattedDuration})</span> 
                </span>
              }
              {x.status === 'running' &&
                <span className='dashboardMiddleGridViewSectionCardStatus'> 
                  <CircularProgress style={{width: 20, height: 20, color: '#00a76f'}}/>
                </span>
              }
              {x.status === 'error' &&
                <span className='dashboardMiddleGridViewSectionCardStatus'> 
                  <span style={{color: '#f44336'}}>Error</span> 
                </span>
              }


            </div>
          )})}


        </div>

        <div className='dashboardMiddleGridViewSection'>
          <span className='dashboardMiddleGridViewSectionTitle'>Processes</span>
          {processes.map((x, index) => { return (
            <div key={index} className='dashboardMiddleGridViewSectionCard'>
              <span className='dashboardMiddleGridViewSectionCardName'>{x.name}</span>
              <span className='dashboardMiddleGridViewSectionCardDescription'>{x.description.length > 50 ? x.description.slice(0,50) + '...' : x.description}</span>
              {x.status === 'done' &&
                <span className='dashboardMiddleGridViewSectionCardStatus'> 
                  <span style={{color: '#00a76f'}}>Done ({x.formattedDuration})</span> 
                </span>
              }
              {x.status === 'running' &&
                <span className='dashboardMiddleGridViewSectionCardStatus'> 
                  <CircularProgress style={{width: 20, height: 20, color: '#00a76f'}}/>
                </span>
              }
              {x.status === 'error' &&
                <span className='dashboardMiddleGridViewSectionCardStatus'> 
                  <span style={{color: '#f44336'}}>Error</span> 
                </span>
              }


            </div>
          )})}
        </div>

      </div>



      
        
      










    </div>
  );
}

export default Dashboard;