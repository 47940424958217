import { ClickAwayListener } from '@mui/material';
import DateSelector from '../components/DateSelector';
import './Count.css';
import dayjs from 'dayjs';
import { React, useState, useEffect } from 'react';
import { CalendarMonthOutlined, CalendarMonthRounded, KeyboardArrowDownRounded } from '@mui/icons-material';
import axios from 'axios';
import ChartComponent from '../components/Charts/Example';
import FileList from '../components/FileList';


function Count ({serverURL, darkMode, refreshFiles, setRefreshFiles, selectedFiles, setSelectedFiles, selectedFileNames, setSelectedFileNames, showSelectionPane, setShowSelectionPane}) {
  const [uniqueAddresses, setUniqueAddresses] = useState(0);
  const [totalAddresses, setTotalAddresses] = useState(0);

  function formatFileSize(bytes) {
    if (bytes === 0) return '0 Bytes';

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    const formattedSize = (bytes / Math.pow(1024, i)).toFixed(2);
    return formattedSize + ' ' + sizes[i];
  }



    return (
    <div className={'CountContainer ' + (darkMode && ' darkMode')}>


      <FileList 
        serverURL={serverURL} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} refreshFiles={refreshFiles} setRefreshFiles={setRefreshFiles}
        selectedFileNames={selectedFileNames} setSelectedFileNames={setSelectedFileNames} showSelectionPane={showSelectionPane} setShowSelectionPane={setShowSelectionPane}
      />
      
          
      {/* <div className={selectedFiles.length > 0 ? 'CountBottomControlRow' : 'CountBottomControlRowHidden'}>


        <div className='CountBottomControlRowTextColumn'>
          <span className='CountBottomControlRowText'>
              {selectedFiles.length}
          </span>
          <span className='CountBottomControlRowDescriptionText'>Selected</span>
        </div>


        <div className='CountBottomControlRowTextColumn'>
          <span className='CountBottomControlRowText'>
            {formatFileSize(selectedFiles.reduce((sum, item) => sum + (item.size || 0), 0))}
          </span>
          <span className='CountBottomControlRowDescriptionText'>Size</span>
        </div>


        <div className='CountBottomControlRowTextColumn'>
          <span className='CountBottomControlRowText'>
              {new Intl.NumberFormat('en-US').format(selectedFiles.reduce((sum, item) => sum + (item?.attributes?.totalEmailCount * 1 || 0), 0))}
          </span>
          <span className='CountBottomControlRowDescriptionText'>Total</span>
        </div>

        
        <div className='CountBottomControlRowTextColumn'>
          <span className='CountBottomControlRowText'>
            {selectedFiles.length < 1 ?
              new Intl.NumberFormat('en-US').format(selectedFiles.reduce((sum, item) => sum + (item?.attributes?.uniqueEmailCount * 1 || 0), 0))
            :
              <>
                {uniqueAddresses == 0 ? 'Calculating...' : new Intl.NumberFormat('en-US').format(uniqueAddresses)}
              </>
            }
          </span>
          <span className='CountBottomControlRowDescriptionText'>Unique</span>
        </div>

        
      </div> */}
    </div>
  )
}



export default Count;