import { ClickAwayListener } from '@mui/material';
import DateSelector from '../components/DateSelector';
import './Columns.css';
import dayjs from 'dayjs';
import { React, useState, useEffect } from 'react';
import { ArrowBack, BackupRounded, CalendarMonthOutlined, CalendarMonthRounded, CloseRounded, KeyboardArrowDownRounded } from '@mui/icons-material';
import axios from 'axios';
import ChartComponent from '../components/Charts/Example';
import FileList from '../components/FileList';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';



function Columns ({serverURL, darkMode, refreshFiles, setRefreshFiles, selectedFiles, setSelectedFiles, selectedFileNames, setSelectedFileNames, showSelectionPane, setShowSelectionPane}) {
  
  const [headers, setHeaders] = useState([])
  const [showColumnsPopup, setShowColumnsPopup] = useState(false)
  const [selectedColumns, setSelectedColumns] = useState([])
  const [columnsInOrder, setColumnsInOrder] = useState([])
  const [popupStep, setPopupStep] = useState(1)
  const [combinationNewColumnNames, setCombinationNewColumnNames] = useState([])
  const [combinationOldColumnNames, setCombinationOldColumnNames] = useState([])
  const [outputFileName, setOutputFileName] = useState('')
  const [renamedColumns, setRenamedColumns] = useState([])
  const [loading, setLoading] = useState(false)






  async function getHeaders () {
    try {
      const data = {
        "filePath": `../../../mnt/lists/${selectedFileNames[0]}`
      }
      const response = await axios.post(`${serverURL}/getHeaders`, data)
      setHeaders(response.data.headers)
      setSelectedColumns(response.data.headers)
    } catch (error) {
      console.log("Error getting headers: ", error) 
    }
  }

  useEffect(() => {
    if (selectedFileNames.length > 0) {
      getHeaders()
      setShowColumnsPopup(true)
    }
  }, [selectedFileNames])




  function adjustSelection(columnName) {
    const selectedColumnNamesSet = new Set(selectedColumns);


    let updatedColumnSelection;
    
    // Check if the columnName name is in selectedColumnNames
    if (selectedColumnNamesSet.has(columnName)) {
        // Remove columnName from selection if it is already selected
        updatedColumnSelection = selectedColumns.filter(item => item !== columnName);
    } else {
        // Add columnName to selection if it is not already selected
        updatedColumnSelection = [...selectedColumns, columnName];
    }
    
    // Update state
    setSelectedColumns(updatedColumnSelection);
}





function getRemovedColumns(array1, array2) {
  return array1.filter(item => !array2.includes(item));
}

const filteredRenameColumns = Object.fromEntries(
  Object.entries(renamedColumns).filter(([key, value]) => key !== value)
);



  async function adjustColumns () {
    try {
      setLoading(true)
      const data = {
        "filePath": `../../../mnt/lists/${selectedFileNames[0]}`,
        "removeColumns": getRemovedColumns(headers, selectedColumns),
        "combinedColumns": {
            [combinationNewColumnNames[0]]: combinationOldColumnNames[0]
        },
        "columnOrder": columnsInOrder,
        "renameColumns": filteredRenameColumns,        //{"subscriber_email": "email", "subscriber_address": "address", "subscriber_city": "city", "subscriber_state": "state", "subscriber_zip": "zip"},
        "outputFileName": outputFileName,
        "totalEmailCount": selectedFiles[0].attributes.totalEmailCount
      }
      console.log(data)
      const response = await axios.post(`${serverURL}/removeColumns`, data)
      console.log(response.data)
      setLoading(false)
      setRefreshFiles(!refreshFiles)
      setShowColumnsPopup(false)
    } catch (error) {
      console.log("Error adjusting columns: ", error) 
      setLoading(false)
    }
  }




  const handleNewColumnNameInputChange = (index, value) => {
    const updatedColumnNames = [...combinationNewColumnNames];
    updatedColumnNames[index] = value;
    setCombinationNewColumnNames(updatedColumnNames);
  };

  const handleOldColumnNameInputChange = (index, value, type) => {
    const updatedColumnNames = [...combinationOldColumnNames];
    if (!updatedColumnNames[index]) {
      updatedColumnNames[index] = [];
    }
    updatedColumnNames[index][type === 'first' ? 0 : 1] = value;
    setCombinationOldColumnNames(updatedColumnNames);
  };

  const handleAddNewCombination = () => {
    setCombinationNewColumnNames([...combinationNewColumnNames, '']);
    setCombinationOldColumnNames([...combinationOldColumnNames, ['', '']]);
  };




  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const reorderedColumns = Array.from(columnsInOrder);
    const [movedColumn] = reorderedColumns.splice(source.index, 1);
    reorderedColumns.splice(destination.index, 0, movedColumn);

    setColumnsInOrder(reorderedColumns);
    setRenamedColumns(Object.fromEntries(reorderedColumns.map(col => [col, col])));

  };




  function handleColumnRenaming(oldName, newName) {
    setRenamedColumns(prev => ({
      ...prev,
      [oldName]: newName
    }));
  }


  function goToStep3() {
    setPopupStep(popupStep + 1); 

    const currentColumnsPlusNewColumns = [...selectedColumns, ...combinationNewColumnNames];
    const columnsWithoutRemovedColumns = currentColumnsPlusNewColumns.filter(item => !combinationOldColumnNames[0]?.includes(item));
    setColumnsInOrder(columnsWithoutRemovedColumns)
  }


  // console.log(combinationNewColumnNames)
  // console.log(combinationOldColumnNames)
  console.log(renamedColumns)


    return (
    <div className={'ColumnsContainer ' + (darkMode && ' darkMode')}>

      <span className='ColumnsDescription'>Select 1 file to get started</span>

      <FileList 
        serverURL={serverURL} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} refreshFiles={refreshFiles} setRefreshFiles={setRefreshFiles}
        selectedFileNames={selectedFileNames} setSelectedFileNames={setSelectedFileNames} showSelectionPane={showSelectionPane} setShowSelectionPane={setShowSelectionPane}
      />
      
          




      <div className={showColumnsPopup ? 'ColumnsPopupBackground' : 'ColumnsPopupBackgroundHidden'}>
        <div className='ColumnsPopupContainer'>
          {popupStep > 1 && (
            <div className='ColumnsPopupContainerBackIcon' onClick={() => {setPopupStep(popupStep - 1)}}>
                <ArrowBack style={{width: 20, color: '#666'}} />
            </div>
          )}
          <div className='ColumnsPopupContainerCloseIcon' onClick={() => {setShowColumnsPopup(false)}}>
              <CloseRounded style={{width: 20, color: '#666'}} />
          </div>




          {popupStep === 1 && (
            <div>
              <span className='ColumnsPopupTitle'>(1/5) Unselect the columns you want to remove from "<b>{selectedFiles[0]?.name}</b>"</span>
              <div className='ColumnsPopupAccountRowContainer'>

                <div className='ColumnNamesContainerScrollContainer'> 
                  <div className='ColumnNamesContainer'>
                    {selectedColumns.length > 0 && (
                      <span className='ColumnName' style={{color: '#fb5e3c', backgroundColor: "#ffeae6"}} onClick={() => {setSelectedColumns([])}}>Clear Selection</span>
                    )}
                      {headers.map((x) => { return (
                          <span key={x} className={selectedColumns.includes(x) ? 'ColumnNameSelected' : 'ColumnName'} onClick={() => {adjustSelection(x)}}>{x}</span>
                      )})}
                  </div>
                </div>

              </div>
          
              <span className='ColumnsPopupSubmitButton' onClick={() => { setPopupStep(popupStep + 1)}}>Next</span>
            </div>
          )}




          {popupStep === 2 && (
            <div>
              <span className='ColumnsPopupTitle'>(2/5) Select the columns you want combined in "<b>{selectedFiles[0]?.name}</b>"</span>
              <div className='ColumnNamesContainer'>
                {combinationNewColumnNames.map((x, i) => (
                  <div className='ColumnNamesContainerRow' key={i}>
                    <div className='ColumnsPopupListNameInputContainer' style={{ width: 150 }}>
                      <input
                        className='ColumnsPopupListNameInput'
                        type='text'
                        value={x}
                        onChange={(e) => handleNewColumnNameInputChange(i, e.target.value)}
                      />
                    </div>
                    <div className='ColumnsPopupListNameInputContainer' style={{ width: 200 }}>
                      <select
                        className='ColumnsPopupListNameInput'
                        value={combinationOldColumnNames[i] ? combinationOldColumnNames[i][0] : ''}
                        onChange={(e) => handleOldColumnNameInputChange(i, e.target.value, 'first')}
                      >
                        {selectedColumns.map((x) => (
                          <option key={x} value={x}>{x}</option>
                        ))}
                      </select>
                    </div>
                    <div className='ColumnsPopupListNameInputContainer' style={{ width: 200 }}>
                      <select
                        className='ColumnsPopupListNameInput'
                        value={combinationOldColumnNames[i] ? combinationOldColumnNames[i][1] : ''}
                        onChange={(e) => handleOldColumnNameInputChange(i, e.target.value, 'second')}
                      >
                        {selectedColumns.map((x) => (
                          <option key={x} value={x}>{x}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                ))}
              </div>
              <span className='ColumnsPopupNewCombinationButton' onClick={handleAddNewCombination}>
                Add combination
              </span>
              <span className='ColumnsPopupSubmitButton' onClick={() => { goToStep3() }}>Next</span>
            </div>
          )}




          {popupStep === 3 && (
            <div>
              <span className='ColumnsPopupTitle'>(3/5) Order the Columns in "<b>{selectedFiles[0]?.name}</b>"</span>
              <div className='ColumnsPopupAccountRowContainer' style={{paddingBottom: 50}}>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="column-list">
                      {(provided) => (
                        <div
                          className='ColumnNamesContainer'
                          style={{flexDirection: 'column', flexWrap: 'nowrap'}}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {columnsInOrder.map((columnName, index) => (
                            <Draggable key={columnName} draggableId={columnName} index={index}>
                              {(provided) => (
                                <span
                                  className='ColumnNameSelected'
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {columnName}
                                </span>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
              </div>
              <span className='ColumnsPopupSubmitButton' onClick={() => { setPopupStep(popupStep + 1)}}>Next</span>
            </div>
          )}






          {popupStep === 4 && (
              <div>
                <span className='ColumnsPopupTitle'>(4/5) Rename Columns in "<b>{selectedFiles[0]?.name}</b>"</span>
                <div className='ColumnsPopupAccountRowContainer'>
                  <div className='ColumnNamesContainerScrollContainer'> 
                    <div className='ColumnNamesContainer' style={{flexDirection: 'column', flexWrap: 'nowrap'}}>
                      {selectedColumns.map((oldName, index) => (
                        <div key={index}>
                          {columnsInOrder.includes(oldName) && (
                            <input
                              key={oldName}
                              value={renamedColumns[oldName] || oldName}
                              className='ColumnsPopupListNameInput'
                              onChange={(e) => handleColumnRenaming(oldName, e.target.value)}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <span className='ColumnsPopupSubmitButton' onClick={() => { setPopupStep(popupStep + 1); }}>Next</span>
              </div>
            )}




          {popupStep === 5 && (
            <div>
              <span className='ColumnsPopupTitle'>(5/5) Output file name for "<b>{selectedFiles[0]?.name}</b>" changes</span>
              <div className='ColumnsPopupAccountRowContainer'>
                <div className='ColumnNamesContainerScrollContainer'> 
                  <div className='ColumnNamesContainer' >
                      <input
                        value={outputFileName}
                        className='ColumnsPopupListNameInput'
                        onChange={(e) => setOutputFileName(e.target.value)}
                      />
                  </div>
                </div>
              </div>
              {loading ? 
                <span className='ColumnsPopupSubmitButton'>Loading</span>
              :
                <span className='ColumnsPopupSubmitButton' onClick={() => { adjustColumns() }}>Process</span>
              }
            </div>
          )}


              
        </div>
      </div>

     



    </div>
  )
}



export default Columns;