import { CircularProgress, ClickAwayListener } from '@mui/material';
import DateSelector from '../components/DateSelector';
import './Combine.css';
import dayjs from 'dayjs';
import { React, useState, useEffect } from 'react';
import { CalendarMonthOutlined, CalendarMonthRounded, CheckRounded, CloseRounded, KeyboardArrowDownRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import axios from 'axios';
import ChartComponent from '../components/Charts/Example';
import FileList from '../components/FileList';
import TextInput from '../components/TextInput';


function Combine ({serverURL, darkMode, refreshFiles, setRefreshFiles, selectedFiles, setSelectedFiles, selectedFileNames, setSelectedFileNames }) {
  const [outputFileName, setOutputFileName] = useState('')
  const [combineLoading, setCombineLoading] = useState(false)




  async function combineFiles() {
    setCombineLoading(true);
    const requestData = {
      "filePath1": selectedFileNames[0],
      "filePath2": selectedFileNames[1],
      "outputFileName": outputFileName,
    };

    console.log('Request Data:', requestData);
  
    try {
      const response = await axios.post(`${serverURL}/combineFiles`, requestData);
      console.log('Response:', response.data);
      setCombineLoading(false);
      setRefreshFiles(!refreshFiles);
    } catch (error) {
      console.error('There was an error!', error);
      setCombineLoading(false);
    }
  }




    return (
    <div className={'CombineContainer ' + (darkMode && ' darkMode')}>


      <div className='CombineOptionsContainer'>
        <input className='CombineOutputFileNameInput' type='text' placeholder='Output File Name' onChange={(e) => {setOutputFileName(e.target.value)}} value={outputFileName}  />
          <div className='CombineOptionsSubmitRow'>
            {selectedFileNames.length > 1 ?
              <div className='CombineOptionsSubmitButton' onClick={() => {combineFiles()}}>
                {combineLoading ? 
                  <CircularProgress style={{width: 20, height: 20, color: '#fff'}} />
                  :
                  "Combine"
                }
                <KeyboardArrowRightRounded style={{width: 20, marginLeft: 10}} />
                
              </div>
            :
              <div className='CombineOptionsSubmitButtonDisabled'>
                Select 2 files
              </div>
            }
          </div>

      </div>


      <FileList 
        serverURL={serverURL} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} refreshFiles={refreshFiles} setRefreshFiles={setRefreshFiles}
        selectedFileNames={selectedFileNames} setSelectedFileNames={setSelectedFileNames}
      />
      


    </div>
  )
}



export default Combine;