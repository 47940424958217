import './Settings.css';
import { getAuth, signOut } from "firebase/auth";
import react, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavbarAndDrawer from '../components/NavbarAndDrawer';
import TextInput from '../components/TextInput';
import { collection, doc, getDoc, getDocs, where, query } from 'firebase/firestore';
import {db} from '../FirebaseInit';
import Plan1Icon from '../assets/Icons/Plan1';
import Plan2Icon from '../assets/Icons/Plan2';
import Plan3Icon from '../assets/Icons/Plan3';
import { AddRounded, KeyboardArrowDownRounded, LockRounded, MenuRounded, MoreVertRounded } from '@mui/icons-material';
import MastercardLogo from '../assets/Icons/MastercardLogo';
import VisaLogo from '../assets/Icons/VisaLogo';
import Switch from "react-switch";
import avatar from '../assets/avatar.png';


function Settings() {
  const navigate = useNavigate()

  const [selectedSection, setSelectedSection] = useState('general')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [country, setCountry] = useState('')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [zip, setZip] = useState('')
  const [selectedPlan, setSelectedPlan] = useState('')
  const [billingName, setBillingName] = useState('')
  const [billingAddress, setBillingAddress] = useState('')
  const [billingPhoneNumber, setBillingPhoneNumber] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [cardIssuer, setCardIssuer] = useState('');
  const [showNewPaymentMethodPopup, setShowNewPaymentMethodPopup] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [cardHolder, setCardHolder] = useState('');
  const [cardExp, setCardExp] = useState('');
  const [cardCVV, setCardCVV] = useState('');
  const [dailySummaryNotifications, setDailySummaryNotifications] = useState(false);
  const [weeklySummaryNotifications, setWeeklySummaryNotifications] = useState(false);
  const [monthlySummaryNotifications, setMonthlySummaryNotifications] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [showEmployeeRoleDropdown, setShowEmployeeRoleDropdown] = useState(false);
  const [employeeRoleChangeValue, setEmployeeRoleChangeValue] = useState('');


  const uid = localStorage.getItem('uid');
  const bid = localStorage.getItem('bid');


    const [underlineLeft, setUnderlineLeft] = useState(0);
    const [underlineWidth, setUnderlineWidth] = useState(0)

    useEffect(() => {
      const selectedTab = document.querySelector(`.settingsSectionsTitleSelected`);
      console.log(selectedTab.width)
      if (selectedTab) {
        setUnderlineLeft(selectedTab.offsetLeft);
        setUnderlineWidth(selectedTab.offsetWidth);
      }
    }, [selectedSection]);



    async function getUserInfo() {
      try {
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setName(docSnap.data().firstName + ' ' + docSnap.data().lastName)
          setEmail(docSnap.data().email)
          setPhone(docSnap.data().phone)
          setAddress(docSnap.data().address)
          setCountry(docSnap.data().country)
          setState(docSnap.data().state)
          setCity(docSnap.data().city)
          setZip(docSnap.data().zip)
        } else {
          console.log("No such document!");
        }
      } catch (e) {
        console.error("Error getting document:", e);
      }
    }

    async function getBillingInfo() {
      try {
        const docRef = doc(db, "businesses", bid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setSelectedPlan(docSnap.data().activePlan)
          setBillingName(docSnap.data().billingName)
          setBillingAddress(docSnap.data().billingAddress)
          setBillingPhoneNumber(docSnap.data().billingPhone)
          setPaymentMethod(docSnap.data().paymentMethod)
        } else {
          console.log("No such document!");
        }
      } catch (e) {
        console.error("Error getting document:", e);
      }
    }

    async function getEmployees() {
      const employeesArray = [];
      const q = query(collection(db, "users"), where("businessID", "==", bid));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        employeesArray.push(doc.data());
      });
      setEmployees(employeesArray);
    }


    useEffect(() => {
      getUserInfo();
      getBillingInfo();
      getEmployees();
    }, [])



    function getCreditCardCompany(cardNumber) {
      // Remove spaces and dashes from the card number
      cardNumber = cardNumber.replace(/[\s-]/g, '');
  
      // Check the starting digits to determine the card issuer
      if (/^4/.test(cardNumber)) {
          return "Visa";
      } else if (/^5[1-5]/.test(cardNumber)) {
          return "Mastercard";
      } else if (/^3[47]/.test(cardNumber)) {
          return "American Express";
      } else if (/^6(?:011|5[0-9]{2}|4[4-9]|4[0-9]{3}|5[0-9]{2}|6[0-9]{2}|7[0-9]{2}|8[0-9]{2})$/.test(cardNumber)) {
          return "Discover";
      } else if (/^(?:30[0-5]|3[89]|36|3095|388[5-9])/g.test(cardNumber)) {
          return "Diners Club";
      } else if (/^3/.test(cardNumber)) {
          return "JCB";
      } else {
          return "Unknown"; // If the card issuer cannot be determined
      }
    }
  
  
    return (
      
    <div>
      <div className="settingsContainer">
      

        {/* <PageHeader title='Settings' grandparent='' parent='Management' child='Settings'/> */}

        

        <div className='settingsSections'>
          <span className={selectedSection === 'general' ? 'settingsSectionsTitleSelected' : 'settingsSectionsTitle'} onClick={() => setSelectedSection('general')}>General</span>
          <span className={selectedSection === 'billing' ? 'settingsSectionsTitleSelected' : 'settingsSectionsTitle'} onClick={() => setSelectedSection('billing')}>Billing</span>
          <span className={selectedSection === 'notifications' ? 'settingsSectionsTitleSelected' : 'settingsSectionsTitle'} onClick={() => setSelectedSection('notifications')}>Notifications</span>
          <span className={selectedSection === 'security' ? 'settingsSectionsTitleSelected' : 'settingsSectionsTitle'} onClick={() => setSelectedSection('security')}>Security</span>
          <span className={selectedSection === 'business' ? 'settingsSectionsTitleSelected' : 'settingsSectionsTitle'} onClick={() => setSelectedSection('business')}>Business</span>
          {/* Underline */}
          <div className="settingsSectionsTitleSelectedUnderline" style={{ left: `${underlineLeft}px`, width: underlineWidth }}></div>
        </div>


        
          {selectedSection == 'general' && 
            <div className='settingsSection'>
              <div className='settingsSectionGeneralLeft'>
                <div className='settingsSectionGeneralAvatarContainer'>
                  <img className='settingsSectionGeneralAvatar' src={avatar}/>
                </div>

                <div className='settingsSectionGeneralIntegrations'>
                  <img className='settingsSectionGeneralIntegrationsPlatformImg' src='https://cdn3.iconfinder.com/data/icons/social-media-2068/64/_shopping-512.png'/>
                  <span className='settingsSectionGeneralIntegrationsPlatformText'>Connect to Shopify</span>
                </div>
                
                <div className='settingsSectionGeneralIntegrations' onClick={() => {navigate('/process-facebook-redirect')}}>
                  <img className='settingsSectionGeneralIntegrationsPlatformImg' src='https://upload.wikimedia.org/wikipedia/commons/6/6c/Facebook_Logo_2023.png'/>
                  <span className='settingsSectionGeneralIntegrationsPlatformText'>Connect to Facebook</span>
                </div>

                <div className='settingsSectionGeneralIntegrations' onClick={() => {navigate('/process-google-redirect')}}>
                  <img className='settingsSectionGeneralIntegrationsPlatformImg' src='https://cdn4.iconfinder.com/data/icons/logos-brands-7/512/google_logo-google_icongoogle-512.png'/>
                  <span className='settingsSectionGeneralIntegrationsPlatformText'>Connect to Google</span>
                </div>

              </div>

              <div className='settingsSectionGeneralRight'>
                <div className='settingsSectionGeneralRightLeft'>
                  <TextInput placeholder='Name' type='text' value={name} onChange={setName} widthPercent={100} widthPixels={0} />
                  <TextInput placeholder='Phone Number' type='text' value={phone} onChange={setPhone} widthPercent={100} widthPixels={0} />
                  <TextInput placeholder='Country' type='text' value={country} onChange={setCountry} widthPercent={100} widthPixels={0} />
                  <TextInput placeholder='City' type='text' value={city} onChange={setCity} widthPercent={100} widthPixels={0} />
                </div>
                <div className='settingsSectionGeneralRightRight'>
                  <TextInput placeholder='Email Address' type='text' value={email} onChange={setEmail} widthPercent={100} widthPixels={0} />
                  <TextInput placeholder='Address' type='text' value={address} onChange={setAddress} widthPercent={100} widthPixels={0} />
                  <TextInput placeholder='State/Region' type='text' value={state} onChange={setState} widthPercent={100} widthPixels={0} />
                  <TextInput placeholder='Zip Code' type='text' value={zip} onChange={setZip} widthPercent={100} widthPixels={0} />
                </div>

              </div>
            </div>
          }

          {selectedSection == 'billing' && 
            <div className='settingsSectionBillingContainer'>
              <div className='settingsBillingSection'>
                <span className='settingsBillingSectionTitle'>Plan</span>
                <div className='settingsBillingPlanOptionsRow'>
                <div className={selectedPlan == 'basic' ? 'settingsBillingPlanOptionSelected' : 'settingsBillingPlanOption'} onClick={() => {setSelectedPlan('basic')}}>
                    <div className='settingsBillingPlanOptionLogo'><Plan1Icon/></div>
                    <span className='settingsBillingPlanOptionTitle'>Basic</span>
                    <span className='setingsBillingPlanOptionPrice'>Free</span>
                  </div>
                  <div className={selectedPlan == 'starter' ? 'settingsBillingPlanOptionSelected' : 'settingsBillingPlanOption'} onClick={() => {setSelectedPlan('starter')}}>
                    <div className='settingsBillingPlanOptionLogo'><Plan2Icon/></div>
                    <span className='settingsBillingPlanOptionTitle'>Starter</span>
                    <span className='setingsBillingPlanOptionPrice'>4.99 /mo</span>
                  </div>
                  <div className={selectedPlan == 'premium' ? 'settingsBillingPlanOptionSelected' : 'settingsBillingPlanOption'} onClick={() => {setSelectedPlan('premium')}}>
                    <div className='settingsBillingPlanOptionLogo'><Plan3Icon/></div>
                    <span className='settingsBillingPlanOptionTitle'>Premium</span>
                    <span className='setingsBillingPlanOptionPrice'>9.99 /mo</span>
                  </div>
                </div>
                <div className='settingsBillingInfo'>
                  <div className='settingsBillingInfoTitles'>
                    <span className='settingsBillingInfoTitle'>Plan</span>
                    <span className='settingsBillingInfoTitle'>Billing name</span>
                    <span className='settingsBillingInfoTitle'>Billing address</span>
                    <span className='settingsBillingInfoTitle'>Billing phone number</span>
                    <span className='settingsBillingInfoTitle'>Payment Method</span>
                  </div>
                  <div className='settingsBillingInfoValues'>
                    <span className='settingsBillingInfoValue'>{selectedPlan}</span>
                    <span className='settingsBillingInfoValue'>{billingName}</span>
                    <span className='settingsBillingInfoValue'>{billingAddress}</span>
                    <span className='settingsBillingInfoValue'>{billingPhoneNumber}</span>
                    <span className='settingsBillingInfoValue'>{paymentMethod}</span>
                  </div>
                </div>
                <div className='settingsBillingSectionFooter'>
                  <span className='settingsBillingCancelPlanButton'>Cancel Plan</span>
                  <span className='settingsBillingSavePlanButton'>Save Plan</span>
                </div>
              </div>

              <div className='settingsBillingSection'>
                <span className='settingsBillingNewCardButton' onClick={() => {setShowNewPaymentMethodPopup(true)}}><AddRounded/> New Card</span>
                <span className='settingsBillingSectionTitle'>Payment Method</span>
                <div className='settingsBillingPaymentMethods'>
                  <div className='settingsBillingPaymentMethod'>
                    <div className='settingsBillingPaymentMethodMoreIcon'><MoreVertRounded/></div>
                    <div className='settingsBillingPaymentMethodCreditCardLogo'>{cardIssuer == 'Visa' ? <VisaLogo/> : <MastercardLogo/>}</div>
                    <span className='settingsBillingPaymentMethodNumber'>**** **** **** 1234</span>
                  </div>
                  <div className='settingsBillingPaymentMethod'>
                    <div className='settingsBillingPaymentMethodMoreIcon'><MoreVertRounded/></div>
                    <div className='settingsBillingPaymentMethodCreditCardLogo'>{cardIssuer == 'Visa' ? <VisaLogo/> : <MastercardLogo/>}</div>
                    <span className='settingsBillingPaymentMethodNumber'>**** **** **** 1234</span>
                  </div>
                  
                </div>
              </div>

            </div>
          }

          {selectedSection == 'notifications' && 
            <div className='settingsSectionBillingContainer'>
              <div className='settingsNotificationsSection'>
                <span className='settingsNotificationsSectionTitle'>Summaries</span>
                <span className='settingsNotificationsSectionTitleDescription'>Notifications will be sent to {email}</span>
                <div className='settingsNotificationsSectionRow'>
                  <Switch
                    onChange={(checked) => setDailySummaryNotifications(checked)}
                    checked={dailySummaryNotifications}
                    offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                  />
                  <span className='settingsNotificationsSectionDescription'>Receive daily summaries of your store's performance</span>
                </div>
                <div className='settingsNotificationsSectionRow'>
                  <Switch
                    onChange={(checked) => setWeeklySummaryNotifications(checked)}
                    checked={weeklySummaryNotifications}
                    offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                  />
                  <span className='settingsNotificationsSectionDescription'>Receive weekly summaries of your store's performance</span>
                </div>
                <div className='settingsNotificationsSectionRow'>
                  <Switch
                    onChange={(checked) => setMonthlySummaryNotifications(checked)}
                    checked={monthlySummaryNotifications}
                    offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                  />
                  <span className='settingsNotificationsSectionDescription'>Receive monthly summaries of your store's performance</span>
                </div>
              </div>

              <div className='settingsNotificationsSection'>
                <span className='settingsNotificationsSectionTitle'>Changes</span>
                <span className='settingsNotificationsSectionTitleDescription'>Notifications will be sent to {email}</span>
                <div className='settingsNotificationsSectionRow'>
                  <Switch
                    onChange={(checked) => setDailySummaryNotifications(checked)}
                    checked={dailySummaryNotifications}
                    offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                  />
                  <span className='settingsNotificationsSectionDescription'>Receive updates for new ads</span>
                </div>
                <div className='settingsNotificationsSectionRow'>
                  <Switch
                    onChange={(checked) => setWeeklySummaryNotifications(checked)}
                    checked={weeklySummaryNotifications}
                    offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                  />
                  <span className='settingsNotificationsSectionDescription'>Receive updates for ad changes</span>
                </div>
                <div className='settingsNotificationsSectionRow'>
                  <Switch
                    onChange={(checked) => setWeeklySummaryNotifications(checked)}
                    checked={weeklySummaryNotifications}
                    offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                  />
                  <span className='settingsNotificationsSectionDescription'>Receive updates for new business users</span>
                </div>
                <div className='settingsNotificationsSectionRow'>
                  <Switch
                    onChange={(checked) => setWeeklySummaryNotifications(checked)}
                    checked={weeklySummaryNotifications}
                    offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                  />
                  <span className='settingsNotificationsSectionDescription'>Receive updates for new Shopify orders created</span>
                </div>
                <div className='settingsNotificationsSectionRow'>
                  <Switch
                    onChange={(checked) => setWeeklySummaryNotifications(checked)}
                    checked={weeklySummaryNotifications}
                    offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                  />
                  <span className='settingsNotificationsSectionDescription'>Receive updates for new Shopify products created</span>
                </div>
              </div>

              <div className='settingsNotificationsSection'>
                <span className='settingsNotificationsSectionTitle'>Analytics</span>
                <span className='settingsNotificationsSectionTitleDescription'>Notifications will be sent to {email}</span>
                <div className='settingsNotificationsSectionRow'>
                  <Switch
                    onChange={(checked) => setDailySummaryNotifications(checked)}
                    checked={dailySummaryNotifications}
                    offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                  />
                  <span className='settingsNotificationsSectionDescription'>Receive notifications for high performing ads</span>
                </div>
                <div className='settingsNotificationsSectionRow'>
                  <Switch
                    onChange={(checked) => setWeeklySummaryNotifications(checked)}
                    checked={weeklySummaryNotifications}
                    offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                  />
                  <span className='settingsNotificationsSectionDescription'>Receive notifications for low performing ads</span>
                </div>
              </div>

            </div>
          }

          {selectedSection == 'security' && 
            <div className='settingsSectionBillingContainer'>
              <div className='securitySection'>
                <span className='settingsSecuritySectionTitle'>Change Password</span>
                <TextInput placeholder='Old Password' type='password' value={''} onChange={() => {}} widthPercent={100} widthPixels={0} />
                <TextInput placeholder='New Password' type='password' value={''} onChange={() => {}} widthPercent={100} widthPixels={0} />
                <TextInput placeholder='Confirm New Password' type='password' value={''} onChange={() => {}} widthPercent={100} widthPixels={0} />
                <span className='settingsSecuritySectionSaveButton'>Save Changes</span>
              </div>
            </div>
          }

          {selectedSection == 'business' && 
            <div className='settingsSection'>
              <div className='settingsSectionBusinessContainer'>
                <span className='settingsSectionBusinessContainerTitle'>Employees</span>
                <div className='settingsBusinessEmployees'>
                  {employees.map((x) => {
                    return (
                      <div className='settingsBusinessEmployeeRow'>
                        <div className='settingsBusinessEmployeeRowLeft'>
                          <img className='settingsBusinessEmployeeRowAvatar' src={avatar}/>
                          <span className='settingsBusinessEmployeeRowName'>{x.firstName} {x.lastName}</span>
                        </div>
                        <div className='settingsBusinessEmployeeRowRight'>
                          <div className='settingsBusinessEmployeeDropdownButtonText' onClick={() => {setShowEmployeeRoleDropdown(showEmployeeRoleDropdown == x.uid ? false : x.uid)}}>
                            <span>{x.role}</span>
                            <KeyboardArrowDownRounded/>
                          </div>
                          {showEmployeeRoleDropdown == x.uid && (
                            <div className='settingsBusinessEmployeeDropdownContainer'>
                              <span className='settingsBusinessEmployeeDropdownRow' onClick={() => {setEmployeeRoleChangeValue("owner"); setShowEmployeeRoleDropdown(false)}}>Owner</span>
                              <span className='settingsBusinessEmployeeDropdownRow' onClick={() => {setEmployeeRoleChangeValue("admin"); setShowEmployeeRoleDropdown(false)}}>Admin</span>
                              <span className='settingsBusinessEmployeeDropdownRow' onClick={() => {setEmployeeRoleChangeValue("marketer"); setShowEmployeeRoleDropdown(false)}}>Marketer</span>
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          }






        </div>




          {showNewPaymentMethodPopup && (
            <div className='settingsNewPaymentMethodPopupBackground'>
              <div className='settingsNewPaymentMethodPopup'>
                <span className='settingsNewPaymentMethodPopupTitle'>New Card</span>
                <TextInput placeholder='Card Number' type='text' value={cardNumber} onChange={setCardNumber} widthPercent={100} widthPixels={0} />
                <TextInput placeholder='Card Holder' type='text' value={cardHolder} onChange={setCardHolder} widthPercent={100} widthPixels={0} />
                <div className='settingsNewPaymentMethodPopupExpAndCVVRow'>
                  <TextInput placeholder='Expiration Date' type='text' value={cardExp} onChange={setCardExp} widthPercent={48} widthPixels={0} />
                  <TextInput placeholder='CVV/CVC' type='text' value={cardCVV} onChange={setCardCVV} widthPercent={48} widthPixels={0} />
                </div>
                <span className='settingsNewPaymentMethodPopupSSLMessage'><LockRounded/> Your transaction is secured with SSL encryption</span>
                <div className='settingsNewPaymentMethodPopupFooter'>
                  <span className='settingsNewPaymentMethodPopupCancelButton' onClick={() => {setShowNewPaymentMethodPopup(false)}}>Cancel</span>
                  <span className='settingsNewPaymentMethodPopupAddButton'>Add</span>
                </div>
              </div>
            </div>
          )}


      </div>
    );
}

export default Settings;