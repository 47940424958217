import { CircularProgress, ClickAwayListener } from '@mui/material';
import DateSelector from '../components/DateSelector';
import './Cleaning.css';
import dayjs from 'dayjs';
import { React, useState, useEffect } from 'react';
import { CalendarMonthOutlined, CalendarMonthRounded, CheckRounded, CloseRounded, KeyboardArrowDownRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import axios from 'axios';
import ChartComponent from '../components/Charts/Example';
import FileList from '../components/FileList';
import TextInput from '../components/TextInput';


function Cleaning ({serverURL, darkMode, refreshFiles, setRefreshFiles, selectedFiles, setSelectedFiles, selectedFileNames, setSelectedFileNames, showSelectionPane, setShowSelectionPane }) {
  const [outputFileName, setOutputFileName] = useState('')
  const [sort, setSort] = useState(false)
  const [sortOrder, setSortOrder] = useState('asc')
  const [showColumnsDropdown, setShowColumnsDropdown] = useState(false)
  const [sortColumn, setSortColumn] = useState('email')
  const [removeDuplicates, setRemoveDuplicates] = useState(false)
  const [sortBy, setSortBy] = useState('none')
  const [removeEmailsBetween, setRemoveEmailsBetween] = useState(false)
  const [removeEmailsLongerThanValue, setRemoveEmailsLongerThanValue] = useState(45)
  const [removeEmailsShorterThanValue, setRemoveEmailsShorterThanValue] = useState(7)
  const [saveRejectedAddresses, setSaveRejectedAddresses] = useState(false)
  const [rejectedAddressesFileName, setRejectedAddressesFileName] = useState('')
  const [cleaningLoading, setCleaningLoading] = useState(false)


  function formatFileSize(bytes) {
    if (bytes === 0) return '0 Bytes';

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    const formattedSize = (bytes / Math.pow(1024, i)).toFixed(2);
    return formattedSize + ' ' + sizes[i];
  }


  async function cleanFile() {
    setCleaningLoading(true);
    // const requestData = {
    //   "input_file": './Files/' + selectedFileNames[0],
    //   "output_file": './Files/' + outputFileName + ".csv",
    //   "removed_file": './Files/' + rejectedAddressesFileName + ".csv",
    //   "sort_by": sortColumn,
    //   "remove_emails_longer_than": removeEmailsLongerThanValue,
    //   "remove_emails_shorter_than": removeEmailsShorterThanValue,
    //   "save_removed": saveRejectedAddresses
    // };


    const requestData = {
      "emailColumn": sortColumn,
      "dedupe": removeDuplicates,
      "sortByDomain": sortBy == 'domain' ? true : false,
      "sortByEmail": sortBy == 'email' ? true : false,
      "removeLongerThan": removeEmailsLongerThanValue,
      "removeShorterThan": removeEmailsShorterThanValue,
      "saveRemoved": saveRejectedAddresses,
      "outputFilePath": outputFileName,
      "removedFilePath": rejectedAddressesFileName.length > 0 && saveRejectedAddresses ? rejectedAddressesFileName : '',
      "inputFilename": selectedFileNames[0],
      "numberOfEmails": selectedFiles[0].attributes.totalEmailCount
    }

    console.log('requestData:', requestData);

  
    try {
      const response = await axios.post(`${serverURL}/clean`, requestData);
      console.log('Response:', response.data);
      setCleaningLoading(false);
      setRefreshFiles(!refreshFiles);
    } catch (error) {
      console.error('There was an error!', error);
      setCleaningLoading(false);
    }
  }




    return (
    <div className={'CleaningContainer ' + (darkMode && ' darkMode')}>

      <div className='CleaningOptionsContainerWrapper'>
        <div className='CleaningOptionsContainer'>
          <input className='CleaningOutputFileNameInput' type='text' placeholder='Output File Name' onChange={(e) => {setOutputFileName(e.target.value)}} value={outputFileName}  />





          <div className='CleaningCheckboxRow'>
            {removeEmailsBetween ?
              <div className='FileListRowCheckboxContainer' onClick={() => { setRemoveEmailsBetween(!removeEmailsBetween) }}>
                <div className='FileListRowCheckboxChecked'>
                    <CheckRounded style={{ width: 15 }} />
                </div>
              </div>
            :
              <div className='FileListRowCheckboxContainer' onClick={() => { setRemoveEmailsBetween(!removeEmailsBetween) }}>
                <div className='FileListRowCheckbox'>
                    <CheckRounded style={{ width: 15 }} />
                </div>
              </div>
            }
            <div className='CleaningCheckboxTextContainer'>
              <span className='CleaningCheckboxText'>Remove emails shorter than</span>
              <input className='CleaningOutputOptionsInput' type='text' placeholder='45' onChange={(e) => {setRemoveEmailsShorterThanValue(e.target.value)}} value={removeEmailsShorterThanValue}  />
            </div>
            <span className='CleaningCheckboxText'>characters and longer than</span> 
            <input className='CleaningOutputOptionsInput' type='text' placeholder='45' onChange={(e) => {setRemoveEmailsLongerThanValue(e.target.value)}} value={removeEmailsLongerThanValue}  />
            <span className='CleaningCheckboxText'>characters</span>
          </div>






          <div className='CleaningCheckboxRow'>
            {sortBy == 'domain' || sortBy == 'email' ?
              <div className='FileListRowCheckboxContainer' onClick={() => { setSortBy('none') }}>
                <div className='FileListRowCheckboxChecked'>
                    <CheckRounded style={{ width: 15 }} />
                </div>
              </div>
            :
              <div className='FileListRowCheckboxContainer' onClick={() => { setSortBy('domain') }}>
                <div className='FileListRowCheckbox'>
                    <CheckRounded style={{ width: 15 }} />
                </div>
              </div>
            }
            <div className='CleaningCheckboxTextContainer'>
              <span className='CleaningCheckboxText'>Sort based on the</span>
              <select className='CleaningColumnsDropdownButton' value={sortBy} onChange={(e) => {setSortBy(e.target.value)}}>
                <option value="email">Email Addresses</option>
                <option value="domain">Email Address Domains</option>
              </select>
            </div>
            <span className='CleaningCheckboxText'>in</span> 
            <select className='CleaningColumnsDropdownButton' value={sortOrder} onChange={(e) => {setSortOrder(e.target.value)}}>
              <option value="desc">DESC</option>
            </select> 
            <span className='CleaningCheckboxText'>order</span>
          </div>



          <div className='CleaningCheckboxRow'>
            {saveRejectedAddresses ?
              <div className='FileListRowCheckboxContainer' onClick={() => { setSaveRejectedAddresses(!saveRejectedAddresses) }}>
                <div className='FileListRowCheckboxChecked'>
                    <CheckRounded style={{ width: 15 }} />
                </div>
              </div>
            :
              <div className='FileListRowCheckboxContainer' onClick={() => { setSaveRejectedAddresses(!saveRejectedAddresses) }}>
                <div className='FileListRowCheckbox'>
                    <CheckRounded style={{ width: 15 }} />
                </div>
              </div>
            }
            <div className='CleaningCheckboxTextContainer'>
              <span className='CleaningCheckboxText'>Save rejected addresses to</span>
              <input className='CleaningOutputOptionsInput' style={{width: 200}} type='text' placeholder='Rejected File Name' onChange={(e) => {setRejectedAddressesFileName(e.target.value)}} value={rejectedAddressesFileName}  />
            </div>
          </div>





          <div className='CleaningCheckboxRow'>
            {removeDuplicates ?
              <div className='FileListRowCheckboxContainer' onClick={() => { setRemoveDuplicates(!removeDuplicates) }}>
                <div className='FileListRowCheckboxChecked'>
                    <CheckRounded style={{ width: 15 }} />
                </div>
              </div>
            :
              <div className='FileListRowCheckboxContainer' onClick={() => { setRemoveDuplicates(!removeDuplicates) }}>
                <div className='FileListRowCheckbox'>
                    <CheckRounded style={{ width: 15 }} />
                </div>
              </div>
            }
            <div className='CleaningCheckboxTextContainer'>
              <span className='CleaningCheckboxText'>Remove duplicates</span>
            </div>
          </div>



            <div className='CleaningOptionsSubmitRow'>
              {selectedFileNames.length > 0 ?
                <div className='CleaningOptionsSubmitButton' onClick={() => {cleanFile()}}>
                  {cleaningLoading ? 
                    <CircularProgress style={{width: 20, height: 20, color: '#fff'}} />
                    :
                    "Clean"
                  }
                  <KeyboardArrowRightRounded style={{width: 20, marginLeft: 10}} />
                  
                </div>
              :
                <div className='CleaningOptionsSubmitButtonDisabled'>
                  Select 1 file to clean
                </div>
              }
            </div>
        </div> 
      </div>

      {/* <span className='CleaningContainerDescription'>Select the files you want to clean</span> */}

      <div className='CleaningFileListScrollContainer'>

        <FileList 
          serverURL={serverURL} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} refreshFiles={refreshFiles} setRefreshFiles={setRefreshFiles}
          selectedFileNames={selectedFileNames} setSelectedFileNames={setSelectedFileNames} showSelectionPane={showSelectionPane} setShowSelectionPane={setShowSelectionPane}
          darkMode={darkMode}
        />

      </div>
      
{/*           
      <div className={selectedFiles.length > 0 ? 'CleaningBottomControlRow' : 'CleaningBottomControlRowHidden'}>
        <span className='CleaningBottomControlRowText'>{selectedFiles.length} Selected</span>
        <span className='CleaningBottomControlRowText'>{formatFileSize(selectedFiles.reduce((sum, item) => sum + (item.size || 0), 0))}</span>
        <span className='CleaningBottomControlRowText'>{new Intl.NumberFormat('en-US').format(selectedFiles.reduce((sum, item) => sum + (item.attributes.totalEmailCount * 1 || 0), 0))} Addresses</span>
      </div>
       */}
    </div>
  )
}



export default Cleaning;