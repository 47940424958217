
import { BrowserRouter, Route, Routes, Navigate, NavLink ,useLocation} from 'react-router-dom';
import SignUp from './pages/SignUp';
import LogIn from './pages/LogIn';
import Dashboard from './pages/Dashboard';
import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Settings from './pages/Settings';
import Files from './pages/Accounts';
import NavbarAndDrawer from './components/NavbarAndDrawer';
import Count from './pages/Count';
import Cleaning from './pages/Cleaning';
import Accounts from './pages/Accounts';
import Combine from './pages/Combine';
import Suppression from './pages/Suppression';
import { collection, query, where, onSnapshot, limit, orderBy, doc, getDoc } from "firebase/firestore";
import {db} from './FirebaseInit';
import Columns from './pages/Columns';
import Sample from './pages/Sample';





function App() {
  const [authenticated, setAuthenticated] = useState(true);
  const [loading, setLoading] = useState(true);
  const [uid, setUid] = useState('')
  const [path, setPath] = useState(window.location.pathname);
  const [darkMode, setDarkMode] = useState(false);
  const [refreshFiles, setRefreshFiles] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([])
  const [selectedFileNames, setSelectedFileNames] = useState([])
  const [showSelectionPane, setShowSelectionPane] = useState(false)
  const [userDetails, setUserDetails] = useState({})


  //const serverURL = 'http://localhost:3000';
  const serverURL = 'https://api.lists.sendwell.com';









  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in
        setUid(user.uid);
        setAuthenticated(true);

      } else {
        // User is signed out
        setAuthenticated(false);
      }
      setLoading(false);
    });

    // Clean up the subscription
    return () => unsubscribe();
  }, []);

  // if (loading) {
  //   return <div>Loading...</div>; // Show a loading state while checking authentication
  // }



  useEffect(() => {
    const fetchUserDetails = async () => {
      console.log(uid)
      if (uid) {
        try {
          const userRef = doc(db, "users", 'u_' + uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            console.log(userSnap.data());
            setUserDetails(userSnap.data());
          } else {
            console.log('No such document!');
          }
        } catch (error) {
          console.error('Error fetching user details:', error);
        }
      }
    };

    fetchUserDetails();
  }, [uid]);




  return (
    
    <BrowserRouter>
      <NavbarAndDrawer 
        collapsed={false} darkMode={darkMode} setDarkMode={setDarkMode} refreshFiles={refreshFiles} setRefreshFiles={setRefreshFiles} 
        selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} selectedFileNames={selectedFileNames} setSelectedFileNames={setSelectedFileNames} 
        showSelectionPane={showSelectionPane} setShowSelectionPane={setShowSelectionPane} userDetails={userDetails} serverURL={serverURL}
      >
        <Routes>
          <Route path="/" element={authenticated ? <Navigate to="/Dashboard" /> : <SignUp darkMode={darkMode}/>} />
          <Route path="/Login" element={<LoginRoute authenticated={authenticated} />} />
          {/* <Route path="/Login" element={authenticated ? <Navigate to="/Dashboard" /> : <LogIn />} /> */}
          <Route path="/Signup" element={<SignUp />} />
          <Route path="/Dashboard"  element={authenticated ? <Dashboard serverURL={serverURL} darkMode={darkMode}/> : <Navigate to="/LogIn" />} />
          <Route path="/Settings"  element={authenticated ? <Settings darkMode={darkMode}/> : <Navigate to="/LogIn" />} />
          <Route path="/Count"  element={authenticated ? <Count serverURL={serverURL} darkMode={darkMode} refreshFiles={refreshFiles} setRefreshFiles={setRefreshFiles} 
            selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} selectedFileNames={selectedFileNames} setSelectedFileNames={setSelectedFileNames} 
            showSelectionPane={showSelectionPane} setShowSelectionPane={setShowSelectionPane}
          /> : <Navigate to="/LogIn" />} 
          />
          <Route path="/Columns"  element={authenticated ? <Columns serverURL={serverURL} darkMode={darkMode} refreshFiles={refreshFiles} setRefreshFiles={setRefreshFiles} 
            selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} selectedFileNames={selectedFileNames} setSelectedFileNames={setSelectedFileNames} 
            showSelectionPane={showSelectionPane} setShowSelectionPane={setShowSelectionPane}
          /> : <Navigate to="/LogIn" />} 
          />
          <Route 
            path="/Cleaning"  element={authenticated ? <Cleaning serverURL={serverURL} darkMode={darkMode} refreshFiles={refreshFiles} setRefreshFiles={setRefreshFiles} 
            selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} selectedFileNames={selectedFileNames} setSelectedFileNames={setSelectedFileNames} 
            showSelectionPane={showSelectionPane} setShowSelectionPane={setShowSelectionPane}
          /> : <Navigate to="/LogIn" />} />
          <Route 
            path="/Combine"  element={authenticated ? <Combine serverURL={serverURL} darkMode={darkMode} refreshFiles={refreshFiles} setRefreshFiles={setRefreshFiles} 
            selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} selectedFileNames={selectedFileNames} setSelectedFileNames={setSelectedFileNames} 
            showSelectionPane={showSelectionPane} setShowSelectionPane={setShowSelectionPane}
          /> : <Navigate to="/LogIn" />} />
          <Route 
            path="/Suppression"  element={authenticated ? <Suppression serverURL={serverURL} darkMode={darkMode} refreshFiles={refreshFiles} setRefreshFiles={setRefreshFiles} 
            selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} selectedFileNames={selectedFileNames} setSelectedFileNames={setSelectedFileNames} 
            showSelectionPane={showSelectionPane} setShowSelectionPane={setShowSelectionPane}
          /> : <Navigate to="/LogIn" />} />
          <Route 
            path="/Sample"  element={authenticated ? <Sample serverURL={serverURL} darkMode={darkMode} refreshFiles={refreshFiles} setRefreshFiles={setRefreshFiles} 
            selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} selectedFileNames={selectedFileNames} setSelectedFileNames={setSelectedFileNames} 
            showSelectionPane={showSelectionPane} setShowSelectionPane={setShowSelectionPane}
          /> : <Navigate to="/LogIn" />} />
          <Route path="/Accounts"  element={authenticated ? <Accounts darkMode={darkMode} serverURL={serverURL} refreshFiles={refreshFiles} setRefreshFiles={setRefreshFiles} /> : <Navigate to="/LogIn" />} />
        </Routes>
      </NavbarAndDrawer>
    </BrowserRouter>
  )
}

const LoginRoute = ({ authenticated }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const user_token = searchParams.get('firebase_token');
  return authenticated ? <Navigate to="/Dashboard" /> : <LogIn user_token={user_token} />;
};

export default App;
