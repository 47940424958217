import { CircularProgress, ClickAwayListener } from '@mui/material';
import DateSelector from '../components/DateSelector';
import './Sample.css';
import dayjs from 'dayjs';
import { React, useState, useEffect } from 'react';
import { CalendarMonthOutlined, CalendarMonthRounded, CheckRounded, CloseRounded, KeyboardArrowDownRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import axios from 'axios';
import ChartComponent from '../components/Charts/Example';
import FileList from '../components/FileList';
import TextInput from '../components/TextInput';


function Sample ({serverURL, darkMode, refreshFiles, setRefreshFiles, selectedFiles, setSelectedFiles, selectedFileNames, setSelectedFileNames, showSelectionPane, setShowSelectionPane }) {
  const [outputFileName, setOutputFileName] = useState('')
  const [shuffle, setShuffle] = useState(false)
  const [usingListRange, setUsingListRange] = useState(false)
  const [listRangeEnd, setListRangeEnd] = useState(100000)
  const [listRangeStart, setListRangeStart] = useState(0)
  const [SampleLoading, setSampleLoading] = useState(false)


  async function cleanFile() {
    setSampleLoading(true);


    const requestData = {
      "csvFilePath": selectedFiles[0].name,
      "outputFilePath": outputFileName,
      "listRangeStart": usingListRange ? listRangeStart : 0,
      "listRangeEnd": usingListRange ? listRangeEnd : 100000000000,
      "shuffle": shuffle,
      "totalEmailCount": selectedFiles[0].attributes.totalEmailCount
  }

    console.log('requestData:', requestData);

  
    try {
      const response = await axios.post(`${serverURL}/sample`, requestData);
      console.log('Response:', response.data);
      setSampleLoading(false);
      setRefreshFiles(!refreshFiles);
    } catch (error) {
      console.error('There was an error!', error);
      setSampleLoading(false);
    }
  }




    return (
    <div className={'SampleContainer ' + (darkMode && ' darkMode')}>

      <div className='SampleOptionsContainerWrapper'>
        <div className='SampleOptionsContainer'>
          <input className='SampleOutputFileNameInput' type='text' placeholder='Output File Name' onChange={(e) => {setOutputFileName(e.target.value)}} value={outputFileName}  />





          <div className='SampleCheckboxRow'>
            {usingListRange ?
              <div className='FileListRowCheckboxContainer' onClick={() => { setUsingListRange(!usingListRange) }}>
                <div className='FileListRowCheckboxChecked'>
                    <CheckRounded style={{ width: 15 }} />
                </div>
              </div>
            :
              <div className='FileListRowCheckboxContainer' onClick={() => { setUsingListRange(!usingListRange) }}>
                <div className='FileListRowCheckbox'>
                    <CheckRounded style={{ width: 15 }} />
                </div>
              </div>
            }
            <div className='SampleCheckboxTextContainer'>
              <span className='SampleCheckboxText'>Range: </span>
              <input className='SampleOutputOptionsInput' type='text' placeholder='45' onChange={(e) => {setListRangeStart(e.target.value)}} value={listRangeStart}  />
            </div>
            <span className='SampleCheckboxText'> - </span> 
            <input className='SampleOutputOptionsInput' type='text' placeholder='45' onChange={(e) => {setListRangeEnd(e.target.value)}} value={listRangeEnd}  />
            <span className='SampleCheckboxText'></span>
          </div>

  





          <div className='SampleCheckboxRow'>
            {shuffle ?
              <div className='FileListRowCheckboxContainer' onClick={() => { setShuffle(!shuffle) }}>
                <div className='FileListRowCheckboxChecked'>
                    <CheckRounded style={{ width: 15 }} />
                </div>
              </div>
            :
              <div className='FileListRowCheckboxContainer' onClick={() => { setShuffle(!shuffle) }}>
                <div className='FileListRowCheckbox'>
                    <CheckRounded style={{ width: 15 }} />
                </div>
              </div>
            }
            <div className='SampleCheckboxTextContainer'>
              <span className='SampleCheckboxText'>Shuffle</span>
            </div>
          </div>



            <div className='SampleOptionsSubmitRow'>
              {selectedFileNames.length > 0 ?
                <div className='SampleOptionsSubmitButton' onClick={() => {cleanFile()}}>
                  {SampleLoading ? 
                    <CircularProgress style={{width: 20, height: 20, color: '#fff'}} />
                    :
                    "Sample"
                  }
                  <KeyboardArrowRightRounded style={{width: 20, marginLeft: 10}} />
                  
                </div>
              :
                <div className='SampleOptionsSubmitButtonDisabled'>
                  Select 1 file to sample
                </div>
              }
            </div>
        </div> 
      </div>

      {/* <span className='SampleContainerDescription'>Select the files you want to clean</span> */}

      <div className='SampleFileListScrollContainer'>

        <FileList 
          serverURL={serverURL} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} refreshFiles={refreshFiles} setRefreshFiles={setRefreshFiles}
          selectedFileNames={selectedFileNames} setSelectedFileNames={setSelectedFileNames} showSelectionPane={showSelectionPane} setShowSelectionPane={setShowSelectionPane}
          darkMode={darkMode}
        />

      </div>
      
{/*           
      <div className={selectedFiles.length > 0 ? 'SampleBottomControlRow' : 'SampleBottomControlRowHidden'}>
        <span className='SampleBottomControlRowText'>{selectedFiles.length} Selected</span>
        <span className='SampleBottomControlRowText'>{formatFileSize(selectedFiles.reduce((sum, item) => sum + (item.size || 0), 0))}</span>
        <span className='SampleBottomControlRowText'>{new Intl.NumberFormat('en-US').format(selectedFiles.reduce((sum, item) => sum + (item.attributes.totalEmailCount * 1 || 0), 0))} Addresses</span>
      </div>
       */}
    </div>
  )
}



export default Sample;