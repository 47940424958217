// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { GoogleAuthProvider } from "firebase/auth";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCVLoh-_xG8xypv-ELYmqmpq40hjIWe6Fc",
  authDomain: "sw-lists.firebaseapp.com",
  databaseURL: "https://sw-lists-default-rtdb.firebaseio.com",
  projectId: "sw-lists",
  storageBucket: "sw-lists.appspot.com",
  messagingSenderId: "705514066189",
  appId: "1:705514066189:web:0725e6c032db3d8f656044",
  measurementId: "G-LCBBHSSNWH"
};
  

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore();

const provider = new GoogleAuthProvider();



export {db}; 